/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { toast } from 'react-toastify';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Modal from '@material-ui/core/Modal';
import { useSpring, animated } from 'react-spring/web.cjs';
import Backdrop from '@material-ui/core/Backdrop';
import ptLocale from 'date-fns/locale/pt';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import {
  Box,
  Card,
  CardHeader,
  Grid,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 50,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root2: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '43%',
  },
  helper: {
    padding: theme.spacing(1, 2),
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const ButtonUpdate = styled(Button)({
  background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 48,
  width: '100%',
  padding: '0 30px',
});

export default function DetailedAccordion({ setUpdate, update }) {
  const classes = useStyles();
  const [allUsers, setAllUsers] = useState([]);
  const [allUserss, setAllUserss] = useState([]);
  const [allServicesUsers, setAllServicesUsers] = useState([]);
  const [services, setServices] = useState([]);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [user, setUser] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadUsers() {
      const response = await api.get(`appointmentsservices`);
      const datas = await response.data.filter((info) => {
        return info.appointments.appointments_status === null;
      });
      const datas2 = await datas.filter((info) => {
        return info.appointments.date === null;
      });

      const servicelocal = datas2.map(function (item) {
        return {
          name: item.appointments.user.name,
          id: item.appointments.user.id,
        };
      });
      const filteredArr = servicelocal.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        }
        return acc;
      }, []);
      setAllUserss(filteredArr);

      setAllUsers(datas2);
    }
    loadUsers();
  }, [update]);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  async function handleOnSelect(item) {
    setUser(item.id);
    const response = await api.get(`appointmentsservices`);
    const datas = await response.data.filter((info) => {
      return info.appointments.appointments_status === null;
    });
    const datas2 = await datas.filter((info) => {
      return info.appointments.date === null;
    });
    const datas3 = await datas2.filter((info) => {
      return info.appointments.user.id === item.id;
    });
    setAllServicesUsers(datas3);
    setUpdate(!update);
  }

  const handleClose = () => {
    setOpen(false);
  };
  async function CreateContactAppointmet() {
    setLoading(true);

    const datas = await allUsers.filter((info) => {
      return info.appointments.user.id === Number(user.user.id);
    });
    const datas3 = await datas.filter((info) => {
      return info.appointments.appointments_status === true;
    });
    if (datas3.length !== 0) {
      handleOpen();
      setLoading(false);
      setUpdate(!update);
    } else {
      const response = await api.put('appointments', {
        id: Number(user.id),
        date: selectedDate,
        obs: 'screening',
        appointments_status: true,
      });
      if (response.status === 200) {
        toast.success('Usuário agendadado para triagem!');
        setUpdate(!update);
        setLoading(false);
      } else {
        toast.error('Erro ao agendar usuário para triagem!');
        setUpdate(!update);
        setLoading(false);
      }
    }
  }
  async function SendData() {
    setLoading(true);
    const response = await api.put('appointments', {
      id: Number(user.id),
      date: selectedDate,
      obs: 'screening',
      appointments_status: true,
    });
    if (response.status === 200) {
      toast.success('Usuário agendadado para triagem!');
      setUpdate(!update);
      handleClose();
      setLoading(false);
    } else {
      setUpdate(!update);
      toast.error('Erro ao agendar usuário para triagem!');
      setLoading(false);
    }
  }
  return (
    <div className={classes.root}>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Usuário possui agendamento ativo deseja continuar "
                title="Alerta"
              />
              <Box display="flex" justifyContent="center" p={2}>
                <Grid container spacing={4}>
                  <Grid item md={6} xs={12}>
                    <ButtonUpdate
                      onClick={SendData}
                      color="primary"
                      variant="contained"
                    >
                      {loading ? 'Carregando...' : 'Sim'}
                    </ButtonUpdate>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <ButtonUpdate
                      onClick={handleClose}
                      color="primary"
                      variant="contained"
                    >
                      Não
                    </ButtonUpdate>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </div>
        </Fade>
      </Modal>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Agendar triagem</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              Coloque o paciente na lista de triagem
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Card className={classes.root2}>
            <CardHeader
              subheader="Agendar paciente para triagem"
              title="Triagem"
            />

            <Divider />
            <Grid container spacing={4}>
              <Grid item md={12} xs={12}>
                <Box pb={12} pt={3} p={3}>
                  <ReactSearchAutocomplete
                    items={allUserss}
                    onSelect={handleOnSelect}
                    placeholder="Buscar clientes"
                    fuseOptions={{
                      shouldSort: true,
                      threshold: 0.6,
                      location: 0,
                      distance: 100,
                      maxPatternLength: 32,
                      minMatchCharLength: 1,
                    }}
                    maxResults={2}
                    styles={{
                      listView: {
                        position: 'absolute',
                        backgroundColor: '#FFF',
                      },
                    }}
                    styling={{
                      borderRadius: '5px',
                      height: '60px',
                      hoverBackgroundColor: '#fff',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={4}>
              <Grid item md={10} xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    style={{ marginLeft: 12 }}
                    id="demo-simple-select-outlined-label"
                  >
                    Selecione o serviço
                  </InputLabel>
                  <Select
                    style={{ marginLeft: 12 }}
                    label="Selecione o serviço"
                    onChange={(event) => {
                      setUser(event.target.value);
                      setServices(event.target.value);
                    }}
                    value={services}
                  >
                    {allServicesUsers &&
                      allServicesUsers.map((option) => (
                        <MenuItem
                          key={option.appointments && option.appointments.id}
                          value={option.appointments && option.appointments}
                        >
                          Serviço:{' '}
                          {option.services && option.services.description}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                  <DatePicker
                    id="date-picker-dialog"
                    ampm={false}
                    label="Data da triagem"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    style={{ marginTop: 9, marginRight: 20 }}
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <ButtonUpdate
                color="primary"
                variant="contained"
                onClick={CreateContactAppointmet}
              >
                {loading ? 'Carregando...' : 'Agendar paciente para triagem'}
              </ButtonUpdate>
            </Box>
          </Card>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
