import styled, { keyframes } from 'styled-components';
import { darken } from 'polished';
import Modal from '@material-ui/core/Modal';

const spin = keyframes`
from{transform:rotate(0deg);}
to{transform:rotate(360deg);}
`;
export const Container = styled.div`
  background-repeat: no-repeat;
  background-color: #fff;
  display: flex;
  background-size: cover;
  flex-direction: column;
  margin-bottom: 50px;
`;

export const Moda = styled(Modal)`
  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    margin: 0px 100px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SecondContainer = styled.div`
  float: none;
  display: block;
  font-weight: 300;
  font-size: 1.1em;
  line-height: 1.4;
  text-align: center;
  letter-spacing: 0.01em;
  margin-right: auto;
  margin-left: auto;
  padding-top: 4rem;
  padding-bottom: 2rem;
  color: white;
  max-width: 1000px;
  background-color: #fff;
`;

export const ThirdContainer = styled.div`
  float: none;
  display: block;
  width: 90%;
  font-weight: 300;
  font-size: 1.1em;
  line-height: 1.4;
  text-align: center;
  letter-spacing: 0.01em;
  margin-top: 2rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0;
  padding-bottom: 0;
  color: white;
  max-width: 1000px;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    input {
      width: 300px;
      background: #57c6ba;
      border: 0;
      border-radius: 4px;
      height: 50px;
      padding: 0px 25px 0px 25px;
      color: #fff;
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
        font-size: 16px;
        font-weight: 600;
      }
    }
    span {
      color: #fb6f91;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
    button {
      margin: 15px 0 0;
      height: 50px;
      width: 300px;
      background: #323232;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 20px;
      font-weight: 600;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, '#87CCC4')};
      }
    }
    a {
      color: #fff;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const OtherContainer = styled.div`
  width: 80%;
`;

export const Title = styled.h2`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.2;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  color: #2d2d2d;
`;

export const Subtitle = styled.p`
  font-family: Roboto;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.8;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  color: #2d2d2d;
`;

export const SubtitleTwo = styled.h3`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  color: #2d2d2d;
`;

export const LinkEmail = styled.a`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.4;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  color: #2d2d2d;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const SubtitleButton = styled.h3`
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 1.4rem;
  line-height: 1.4;
  color: #fff;
`;

export const ImageContainer = styled.div`
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-self: center;
    margin: 0px;
  }
  margin: 0px 150px;

  display: flex;
  flex-direction: row;
  padding-top: 30px;
  justify-content: space-between;
  align-self: center;
  button {
    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
      margin-left: 0px;
      align-self: center;
    }
    flex-direction: column;
    align-items: center;
    height: 125px;
    width: 300px;
    background: #015f59;
    color: #fff;
    border: 0;
    border-radius: 4px;
    margin-left: 30px;
    font-size: 20px;
    font-weight: 600;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, '#87CCC4')};
    }
  }
`;

export const ImgTextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Health = styled.div`
  @media screen and (max-width: 768px) {
    margin-right: 0px;
  }
  margin-top: 40px;
  margin-right: 30px;
  width: 150px;
  height: 150px;
  &:hover {
    animation: ${spin} 1s ease-in-out infinite;
  }
`;

export const Money = styled.div`
  @media screen and (max-width: 768px) {
    margin-right: 0px;
  }
  margin-top: 40px;
  margin-right: 30px;
  width: 150px;
  height: 150px;
  &:hover {
    animation: ${spin} 1s ease-in-out infinite;
  }
`;

export const Stress = styled.div`
  @media screen and (max-width: 768px) {
    margin-right: 0px;
  }
  width: 150px;
  height: 150px;
  margin-top: 40px;
  margin-right: 30px;
  &:hover {
    animation: ${spin} 1s ease-in-out infinite;
  }
`;

export const Sustentability = styled.div`
  @media screen and (max-width: 768px) {
    margin-right: 0px;
  }
  margin-right: 30px;
  margin-top: 40px;
  width: 150px;
  height: 150px;
  &:hover {
    animation: ${spin} 1s ease-in-out infinite;
  }
`;

export const Time = styled.div`
  @media screen and (max-width: 768px) {
    margin-right: 0px;
  }
  margin-top: 40px;
  margin-right: 30px;
  width: 150px;
  height: 150px;
  &:hover {
    animation: ${spin} 1s ease-in-out infinite;
  }
`;
