import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import Route from './Route';
import SignIn from '../pages/SignIn';
// import LandingPage from '../pages/LandingPage';

import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import ShowContracts from '../pages/ShowContracts';
import ShowUsers from '../pages/ShowUsers';

import CreateContracts from '../pages/CreateContracts';
import CreateClients from '../pages/CreateClients';
import CreateUsers from '../pages/CreateUsers';
import CreateDoctor from '../pages/CreateDoctor';

import EditContract from '../pages/EditContracts';
import ConsultServices from '../pages/ConsultServices';
import ShowClients from '../pages/ShowClients';
import ShowDoctor from '../pages/ShowDoctor';
import ConsultAppointment from '../pages/ConsultAppointment';
import AddAppointment from '../pages/AddAppointment';
import CreateJustification from '../pages/CreateJustification';
import ConsultApac from '../pages/ConsultApac';
import PrintApac from '../pages/ConsultApac/PrintApac';
import LandingPage from '../pages/LandingPage';

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={LandingPage} />
      <Route path="/login" component={SignIn} />
      <Route path="/signUp" component={SignIn} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/showContracts" component={ShowContracts} isPrivate />
      <Route path="/createContracts" component={CreateContracts} isPrivate />
      <Route path="/addClient" component={CreateClients} isPrivate />
      <Route path="/editContract" component={EditContract} isPrivate />
      <Route path="/consultServices" component={ConsultServices} isPrivate />
      <Route path="/showClients" component={ShowClients} isPrivate />
      <Route path="/createUsers" component={CreateUsers} isPrivate />
      <Route path="/showUsers" component={ShowUsers} isPrivate />
      <Route path="/createDoctors" component={CreateDoctor} isPrivate />
      <Route path="/showDoctors" component={ShowDoctor} isPrivate />
      <Route path="/addAppointment" component={AddAppointment} isPrivate />
      <Route
        path="/createJustification"
        component={CreateJustification}
        isPrivate
      />
      <Route
        path="/ConsultAppointment"
        component={ConsultAppointment}
        isPrivate
      />
      <Route path="/consultarApac" component={ConsultApac} isPrivate />
      <Route path="/printApac" component={PrintApac} isPrivate />
    </Switch>
  );
}
export default withRouter(Routes);
