/* eslint-disable no-await-in-loop */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring/web.cjs';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ptLocale from 'date-fns/locale/pt';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 800,
  },
  datePicker: {
    border: 2,
    borderColor: '#000',
    borderBottomColor: '#FFF',
    width: '100%',
    boxShadow: '0 3px 5px 2px rgba(128, 210, 201 ,.3)',
  },
}));

const TextFieldEdit = styled(TextField)({
  border: 0,
  borderColor: '#FF8E53',
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(128, 210, 201 ,.3)',
  color: 'white',
});

const ButtonUpdate = styled(Button)({
  background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 48,
  width: '100%',
  padding: '0 30px',
});

const ButtonQuit = styled(Button)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 55,
  width: 150,
});

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [services, setServices] = React.useState([]);
  const [cont, setCont] = React.useState(1);
  const [loading, setLoading] = useState(false);

  const [environmentVariables, setEnvironmentVariables] = useState([]);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    async function loadServices() {
      const response = await api.get(`allServices`);
      response.data.unshift({
        description: 'Escolha',
      });
      setServices(response.data);
    }

    loadServices();
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleUpdateEnvironmentVariable(_id, field, value) {
    const environmentVariableslocal = [...environmentVariables];
    const variableToUpdate = environmentVariableslocal.find(
      (variable) => variable._id === _id
    );
    variableToUpdate[field] = value;
    setEnvironmentVariables(environmentVariableslocal);
  }

  function handleDeleteEnvironmentVariable(_id) {
    const environmentVariablesLocal = [...environmentVariables].filter(
      (variable) => variable._id !== _id
    );
    setEnvironmentVariables(environmentVariablesLocal);
  }

  function addEnvironmentVariable() {
    const environmentVariablesLocal = [...environmentVariables];
    environmentVariablesLocal.push({
      _id: cont,
      amount: '',
      servicechoose: '',
      uvalue: '',
    });
    setCont(cont + 1);
    setEnvironmentVariables(environmentVariablesLocal);
  }

  async function sendData() {
    setLoading(true);
    const response = await api.post('contract', {
      code: values.code,
      name: values.name,
      date_contract: selectedDate,
      quotas: 1,
      active: true,
    });
    if (response.status === 200) {
      toast.success('Contrato criado com sucesso!');
      let response2 = [];
      for (let i = 0; i < environmentVariables.length; i += 1) {
        response2 = await api.post('contractServicesController', {
          contract_id: response.data.id,
          service_id: Number(environmentVariables[i].servicechoose),
          amount: Number(environmentVariables[i].amount),
          uvalue: Number(environmentVariables[i].uvalue),
        });
        toast.success('Serviços adicionados com sucesso!');
      }
      if (response2.status === 200) {
        const response3 = await api.put('contract', {
          id: response.data.id,
          quotas: environmentVariables.length,
        });
        if (response3.status === 200) {
          toast.success('Contrato criado com sucesso e pronto para uso!');
          setLoading(false);
        } else {
          toast.error('Erro ao criar contrato tente novamente!');
          setLoading(false);
        }
      } else {
        toast.error('Erro ao adicionar serviços!');
        setLoading(false);
      }
    } else {
      toast.error('Erro ao criar contrato tente novamente!');
      setLoading(false);
    }
  }
  return (
    <>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Adicionar serviços ao contrato"
                title="Serviços"
              />
              <Divider />
              {environmentVariables.map(
                ({ _id, servicechoose, amount, uvalue }) => (
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={3} xs={12}>
                        <TextField
                          fullWidth
                          label="Selecione o serviço"
                          name="service"
                          value={servicechoose}
                          onChange={(event) =>
                            handleUpdateEnvironmentVariable(
                              _id,
                              'servicechoose',
                              event.target.value
                            )
                          }
                          select
                          SelectProps={{ native: true }}
                          variant="outlined"
                        >
                          {services &&
                            services.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.description}
                              </option>
                            ))}
                        </TextField>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextFieldEdit
                          fullWidth
                          label="Quantidade"
                          name="amount"
                          value={amount}
                          onChange={(event) =>
                            handleUpdateEnvironmentVariable(
                              _id,
                              'amount',
                              event.target.value
                            )
                          }
                          variant="outlined"
                          type="number"
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextFieldEdit
                          fullWidth
                          label="Valor"
                          name="uvalue"
                          value={uvalue}
                          onChange={(event) =>
                            handleUpdateEnvironmentVariable(
                              _id,
                              'uvalue',
                              event.target.value
                            )
                          }
                          variant="outlined"
                          type="number"
                        />
                      </Grid>
                      <Box
                        display="flex"
                        md={3}
                        xs={12}
                        justifyContent="center"
                        p={1.8}
                      >
                        <ButtonQuit
                          onClick={() => handleDeleteEnvironmentVariable(_id)}
                          color="alert"
                          variant="contained"
                        >
                          X
                        </ButtonQuit>
                      </Box>
                    </Grid>
                  </CardContent>
                )
              )}
              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  color="primary"
                  variant="contained"
                  onClick={addEnvironmentVariable}
                >
                  Adicionar serviço
                </ButtonUpdate>
              </Box>
              <Divider />

              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  color="primary"
                  variant="contained"
                  onClick={sendData}
                >
                  {loading ? 'Carregando...' : 'Criar contrato'}
                </ButtonUpdate>
              </Box>
            </Card>
          </div>
        </Fade>
      </Modal>
      <form
        autoComplete="off"
        noValidate
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Card>
          <CardHeader subheader="Informações do contrato" title="Contrato" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextFieldEdit
                  fullWidth
                  label="Código"
                  name="code"
                  onChange={handleChange}
                  value={values.code}
                  variant="outlined"
                  type="number"
                />
              </Grid>
              <Grid item md={10} xs={12}>
                <TextFieldEdit
                  fullWidth
                  label="Nome do contrato"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                <Grid item md={2} xs={12}>
                  <DatePicker
                    id="date-picker-dialog"
                    ampm={false}
                    label="Data do contrato"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <ButtonUpdate
              color="primary"
              variant="contained"
              onClick={() => handleOpen()}
            >
              Adicionar Serviços
            </ButtonUpdate>
          </Box>
        </Card>
      </form>
    </>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
