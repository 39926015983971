/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { updateProfileRequest } from '../../store/modules/user/actions';
import { signOut } from '../../store/modules/auth/actions';

const useStyles = makeStyles(() => ({
  root: {},
}));

const TextFieldEdit = styled(TextField)({
  border: 0,
  borderColor: '#FF8E53',
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(128, 210, 201 ,.3)',
  color: 'white',
});

const ButtonQuit = styled(Button)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  width: '100%',
  padding: '0 30px',
});

const ButtonUpdate = styled(Button)({
  background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 48,
  width: '100%',
  padding: '0 30px',
});

const ProfileDetails = ({ className, ...rest }) => {
  const profile = useSelector((state) => state.user.profile);
  const history = useHistory();

  const classes = useStyles();
  const [values, setValues] = useState({
    name: profile.name,
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  async function handleSubmit() {
    const data = {
      name: values.name,
      oldPassword: values.oldPassword,
      password: values.password,
      confirmPassword: values.confirmPassword,
      cpfcnpj: profile.cpfcnpj,
    };
    setLoading(true);
    await dispatch(updateProfileRequest(data));
    setLoading(false);
  }

  function handleSignOut() {
    dispatch(signOut());
    history.push('/');
  }
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Informações para editar"
          title="Editar Profile"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextFieldEdit
                fullWidth
                label="Nome"
                name="name"
                onChange={handleChange}
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextFieldEdit
                fullWidth
                label="Senha antiga"
                name="oldPassword"
                onChange={handleChange}
                value={values.oldPassword}
                variant="outlined"
                type="password"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextFieldEdit
                fullWidth
                label="Nova senha"
                name="password"
                onChange={handleChange}
                value={values.password}
                variant="outlined"
                type="password"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextFieldEdit
                fullWidth
                label="Nova senha"
                name="confirmPassword"
                onChange={handleChange}
                value={values.confirmPassword}
                variant="outlined"
                type="password"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <ButtonUpdate
            onClick={handleSubmit}
            color="primary"
            variant="contained"
          >
            {loading ? 'Carregando...' : 'Salvar dados'}
          </ButtonUpdate>
        </Box>
        <Box display="flex" justifyContent="center" p={2}>
          <ButtonQuit onClick={handleSignOut} color="alert" variant="contained">
            Sair do sistema
          </ButtonQuit>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
