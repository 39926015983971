/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Grid,
  CardContent,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import { styled } from '@material-ui/core/styles';
import { useSpring, animated } from 'react-spring/web.cjs';
import api from '../../services/api';

const useStyles = makeStyles(() => ({
  root: {},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const ButtonUpdate = styled(Button)({
  background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 40,
  width: 100,
});

const ButtonQuit = styled(Button)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 40,
  width: 100,
});
const TextFieldEdit = styled(TextField)({
  border: 0,
  borderColor: '#FF8E53',
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(128, 210, 201 ,.3)',
  color: 'white',
});

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const LatestOrders = ({ className, ...rest }) => {
  const classes = useStyles();
  const [services, setServices] = useState([]);
  const [values, setValues] = useState({});
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [idservice, setIdService] = useState([]);
  const [loading, setLoading] = useState(false);

  const [del, setDel] = useState([]);

  useEffect(() => {
    async function loadServices() {
      const response = await api.get(`allServices`);
      setServices(response.data);
    }

    loadServices();
  }, [del]);
  const handleOpen = (order) => {
    setOpen(true);
    setValues({
      code: order.code,
      description: order.description,
    });
    setIdService(order.id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  async function deleteService(id) {
    const response = await api.get(`allContractServices`);
    const datas = response.data.filter((info) => {
      return info.services.id === id;
    });
    if (datas.length === 0) {
      setLoading(true);
      const response2 = await api.delete(`services/${id}`);
      if (response2.status === 200) {
        setDel(!del);
        toast.success('Serviço removido com sucesso');
        setLoading(false);
      } else {
        toast.error('Erro ao remover servivço');
        setLoading(false);
      }
    } else {
      toast.error(
        'Vocé não pode deletar este servivço pois ele esta lincado a um contrato'
      );
    }
  }
  async function updateData() {
    setLoading(true);
    const response = await api.put('services', {
      id: idservice,
      code: values.code,
      description: values.description,
    });
    if (response.status === 200) {
      setDel(!del);
      toast.success('Serviço editado com sucesso');
      handleClose();
      setLoading(false);
    } else {
      toast.error('Erro ao editar servivço');
      setLoading(false);
    }
  }
  async function sendData() {
    setLoading(true);
    const response = await api.post('services', {
      code: values.code,
      description: values.description,
    });
    if (response.status === 200) {
      setDel(!del);
      toast.success('Serviço adicionado com sucesso');
      handleClose2();
      setLoading(false);
    } else {
      toast.error('Erro ao adicionar servivço');
      setLoading(false);
    }
  }
  return (
    <Card>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title="Serviços" />
        <Divider />
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <Card>
                <CardHeader subheader="Editar serviço" title="Serviços" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextFieldEdit
                        fullWidth
                        label="Código"
                        name="code"
                        value={values.code}
                        onChange={handleChange}
                        variant="outlined"
                        type="number"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextFieldEdit
                        fullWidth
                        label="Nome"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <ButtonUpdate
                    onClick={updateData}
                    color="primary"
                    variant="contained"
                  >
                    {loading ? 'Carregando...' : 'Atualizar'}
                  </ButtonUpdate>
                </Box>
              </Card>
            </div>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          className={classes.modal}
          open={open2}
          onClose={handleClose2}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open2}>
            <div className={classes.paper}>
              <Card>
                <CardHeader subheader="Editar serviço" title="Serviços" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextFieldEdit
                        fullWidth
                        label="Código"
                        name="code"
                        value={values.code}
                        onChange={handleChange}
                        variant="outlined"
                        type="number"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextFieldEdit
                        fullWidth
                        label="Nome"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <ButtonUpdate
                    onClick={sendData}
                    color="primary"
                    variant="contained"
                  >
                    {loading ? 'Carregando...' : 'Adicionar'}
                  </ButtonUpdate>
                </Box>
              </Card>
            </div>
          </Fade>
        </Modal>
        <PerfectScrollbar>
          <Box maxHeight={600}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Editar</TableCell>
                  <TableCell>Remover</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {services.map((order) => (
                  <TableRow hover key={order.id}>
                    <TableCell>{order.code}</TableCell>
                    <TableCell>{order.description}</TableCell>
                    <TableCell>
                      <ButtonUpdate
                        onClick={() => handleOpen(order)}
                        color="alert"
                        variant="contained"
                      >
                        Editar
                      </ButtonUpdate>
                    </TableCell>
                    <TableCell>
                      <ButtonQuit
                        onClick={() => deleteService(order.id)}
                        color="alert"
                        variant="contained"
                      >
                        {loading ? 'Carregando...' : 'Remover'}
                      </ButtonQuit>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
      <Card>
        <Box display="flex" justifyContent="flex-end" p={3}>
          <Button
            color="segundary"
            onClick={handleOpen2}
            size="small"
            variant="text"
          >
            Adicionar Serviço
          </Button>
        </Box>
      </Card>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string,
};

export default LatestOrders;
