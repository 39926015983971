/* eslint-disable no-await-in-loop */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
import { lastDayOfMonth, subHours, getMonth, getYear } from 'date-fns';
import {
  makeStyles,
  Box,
  Card,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  TextField,
  Modal,
  Backdrop,
  Icon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import ceps from 'cep-promise';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';

import moment from 'moment';

import { useSpring, animated } from 'react-spring/web.cjs';
import { toast } from 'react-toastify';
import { styled } from '@material-ui/core/styles';
import ptLocale from 'date-fns/locale/pt';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import Calendar from 'react-calendar';
import Page from '../../components/Page';

import {
  Container,
  Time,
  ContainerCalendar,
  ConfirmationButton,
} from './styles';
import api from '../../services/api';
import 'react-calendar/dist/Calendar.css';
import getInitials from '../../utils/getInitials';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

const ButtonUpdate = styled(Button)({
  background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
  border: 0,
  borderRadius: 3,
  marginTop: 5,
  marginLeft: -20,
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 48,
});

const ButtonCPF = styled(Button)({
  background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
  border: 0,
  borderRadius: 3,
  marginTop: 5,
  width: '100%',
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 48,
});

const ButtonQuit = styled(Button)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  marginTop: 5,
  marginLeft: -15,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
});

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const sex = [
  {
    value: 'Selecione',
    label: 'Selecione',
  },
  {
    value: false,
    label: 'Feminino',
  },
  {
    value: true,
    label: 'Masculino',
  },
];

const color = [
  {
    value: 'Selecione',
    label: 'Selecione',
  },
  {
    value: 'Preta',
    label: 'Preta',
  },
  {
    value: 'Branca',
    label: 'Branca',
  },
  {
    value: 'Parda',
    label: 'Parda',
  },
  {
    value: 'Amarela',
    label: 'Amarela',
  },
  {
    value: 'Indígena',
    label: 'Indígena',
  },
];
const ethnicity = [
  {
    value: 'Selecione',
    label: 'Selecione',
  },
  {
    value: 'Mulatos',
    label: 'Mulatos',
  },
  {
    value: 'Caboclos',
    label: 'Caboclos',
  },
  {
    value: 'Cafuzo',
    label: 'Cafuzo',
  },
  {
    value: 'Indígenas',
    label: 'Indígenas',
  },
  {
    value: 'Brancos',
    label: 'Brancos',
  },
  {
    value: 'Negros',
    label: 'Negros',
  },
];

function ShowAppointments() {
  const [appointments, setAppointments] = useState([]);
  const [value, onChange] = useState(new Date());
  const [reaload, setReload] = useState([]);
  const [dates, setDates] = useState([]);
  const [allDoctors, setAllDoctors] = useState([]);
  const [appointmentServices, setAppointmentServices] = useState([]);
  const [status, setStatus] = useState(false);
  const [environmentVariables, setEnvironmentVariables] = useState([]);
  const [cont, setCont] = useState(1);
  const [allContractServices, setAllContractServices] = useState([]);
  const [allAppointmentServiceId, setAllAppointmentServiceId] = useState([]);

  const [allJustification, setAllJustification] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [clients, setClients] = useState([]);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    city: '',
    neighborhood: '',
    uf: '',
    street: '',
  });
  const [find, setFind] = useState(false);
  const [load, setLoad] = useState(false);
  const [button, setButton] = useState(false);
  const [userAppointment, setUserAppointment] = useState({
    user: {
      name: ' ',
    },
    doctor: { name: ' ' },
  });

  const classes = useStyles();

  useEffect(() => {
    async function loadClientss() {
      const response = await api.get(`appointmentsservices`);

      const datas6 = await response.data.filter((info) => {
        return info.appointments.obs !== 'wait';
      });
      const servicelocal = datas6.map(function (item) {
        return {
          name: item.appointments.user.name,
          id: item.appointments.user.id,
        };
      });
      const filteredArr = servicelocal.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        }
        return acc;
      }, []);
      setClients(filteredArr);
    }

    async function loadAppointments() {
      const response = await api.post('appointmentsservicesIndexWithDate', {
        date: new Date(getYear(value), getMonth(value), 1),
        dateTwo: new Date(lastDayOfMonth(value)),
      });
      const datas5 = await response.data.filter((info) => {
        return info.appointments.obs !== 'wait';
      });
      const datas4 = await datas5.filter((info) => {
        return (
          moment(info.appointments.date).format('DD/MM/YYYY') ===
          moment(value).format('DD/MM/YYYY')
        );
      });

      const datesMark = datas5.map(function (item) {
        return item.appointments.date;
      });

      setDates(datesMark);
      setAppointments(datas4);
    }

    async function loadDoctors() {
      const response = await api.get(`alldoctors`);
      response.data.unshift({ name: 'Escolha', id: null });
      setAllDoctors(response.data);
    }

    async function loadContractServices() {
      const response = await api.get(`allContractServices`);
      setAllContractServices(response.data);
    }

    async function loadJustification() {
      const response = await api.get(`justification`);
      setAllJustification(response.data);
    }
    loadContractServices();
    loadDoctors();
    loadAppointments();
    loadJustification();
    loadClientss();
  }, [value, reaload, load]);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleOpen = (customer) => {
    setSelectedDate(customer.born);
    setValues({
      ...values,
      id: customer.id,
      name: customer.name,
      namefantasy: customer.namefantasy,
      nmother: customer.nmother,
      nresponsible: customer.nresponsible,
      cpfcnpj: customer.cpfcnpj,
      ceap: customer.ceap,
      rg: customer.rg,
      cns: customer.cns,
      ddd: customer.ddd,
      phoneone: customer.phoneone,
      phonetwo: customer.phonetwo,
      male: customer.male,
      breed: customer.breed,
      ethnicity: customer.ethnicity,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen4 = async (id) => {
    const response = await api.get('appointmentsservices');
    const datas = await response.data.filter((info) => {
      return info.appointment_id === id;
    });
    setAppointmentServices(datas);
    setOpen4(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };
  const handleOpen6 = (customer) => {
    setValues({
      ...values,
      id: customer.id,
      description: customer.description,
      cid10p: customer.cid10p,
      cid10s: customer.cid10s,
      cid10ac: customer.cid10ac,
      obsj: customer.obsj,
    });
    setOpen6(true);
  };

  const handleClose6 = () => {
    setOpen6(false);
  };
  async function deleteService(id) {
    setLoading(true);
    const response = await api.delete(`appointmentsservices/${id}`);
    if (response.status === 200) {
      toast.success('Serviço removido com sucesso');
      const response2 = await api.get('appointmentsservices');
      const datas = await response2.data.filter((info) => {
        return info.appointment_id === id;
      });
      setAppointmentServices(datas);
      setLoading(false);
    } else {
      toast.error('Erro ao remover serviço tente novamente!');
      setLoading(false);
    }
  }

  const handleOpen2 = async (customer) => {
    const response = await api.get(`address/${customer.id}`);
    if (response.data.length === 0) {
      setButton(false);
      setValues({
        ...values,
        id: customer.id,
        cep: ' ',
        street: ' ',
        number: ' ',
        city: ' ',
        neighborhood: ' ',
        uf: ' ',
        cibge: ' ',
      });
    } else {
      setButton(true);
      setValues({
        ...values,
        id: customer.id,
        cep: response.data[0].cep,
        street: response.data[0].street,
        number: response.data[0].number,
        city: response.data[0].city,
        neighborhood: response.data[0].neighborhood,
        uf: response.data[0].uf,
        cibge: response.data[0].cibge,
      });
    }
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleOpen3 = (customer, statuskey, appointment) => {
    setAllAppointmentServiceId(appointment);
    setStatus(statuskey);
    setUserAppointment(customer);
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleOpen5 = () => {
    setOpen5(true);
  };

  const handleClose5 = () => {
    setOpen5(false);
  };
  async function checkCep() {
    await ceps(values.cep)
      .then(async (res) => {
        setFind(true);
        await setValues({
          ...values,
          city: res.city,
          neighborhood: res.neighborhood,
          uf: res.state,
          street: res.street,
        });
      })
      .catch(() => {
        setFind(false);
        toast.error('Cep invalido!');
      });
  }
  async function sendData() {
    setLoading(true);
    const response = await api.put('usersAdmin', {
      id: values.id,
      name: values.name,
      namefantasy: values.namefantasy,
      nmother: values.nmother,
      nresponsible: values.nresponsible,
      cpfcnpj: values.cpfcnpj,
      ceap: values.ceap,
      rg: values.rg,
      cns: values.cns,
      ddd: values.ddd,
      born: selectedDate,
      phoneone: values.phoneone,
      phonetwo: values.phonetwo,
      male: values.male,
      breed: values.breed,
      ethnicity: values.ethnicity,
    });
    if (response.status === 200) {
      setLoad(!load);
      toast.success('Dados atualizados com sucesso!');
      handleClose();
      handleClose3();
      setLoading(false);
    } else {
      toast.error('Erro ao atualizar dados!');
      setLoading(false);
    }
  }
  async function updateAddress() {
    if (find === true) {
      setLoading(true);
      const response = await api.put('address', {
        id: values.id,
        cep: values.cep,
        street: values.street,
        number: values.number,
        city: values.city,
        neighborhood: values.neighborhood,
        uf: values.uf,
        cibge: values.cibge,
      });
      if (response.status === 200) {
        setLoad(!load);
        toast.success('Dados do endereço atualizados com sucesso!');
        handleClose2();
        handleClose3();
        setLoading(false);
      } else {
        toast.error('Erro ao atualizar dados de endereço!');
        setLoading(false);
      }
    } else {
      toast.error('Pesquise antes o cep!');
    }
  }
  async function removeAppointment(data) {
    const response = await api.post('appointmentsRemove', {
      id: data,
    });
    if (response.status === 200) {
      toast.success('Serviço removido com sucesso');
      setLoad(!load);
    } else {
      toast.error('Erro ao remover serviço tente novamente!');
      setLoad(!load);
    }
  }
  async function sendAddress() {
    if (find === true) {
      setLoading(true);
      const response = await api.post('address', {
        id: values.id,
        cep: values.cep,
        street: values.street,
        number: values.number,
        city: values.city,
        neighborhood: values.neighborhood,
        cibge: values.cibge,
        uf: values.uf,
      });
      if (response.status === 200) {
        setLoad(!load);
        toast.success('Dados do endereço enviados com sucesso!');
        handleClose2();
        handleClose3();
        setLoading(false);
      } else {
        toast.error('Erro ao enviar dados de endereço!');
        setLoading(false);
      }
    } else {
      toast.error('Pesquise antes o cep!');
    }
  }
  async function handleOnChange(item) {
    if (item !== '') {
      const response = await api.get(`appointmentsservices`);

      const datas6 = await response.data.filter((info) => {
        return info.appointments.obs !== 'wait';
      });
      const datas2 = await datas6.filter((info) => {
        return info.appointments.user.id === item.id;
      });
      setAppointments(datas2);
    } else {
      const response = await api.get(`appointmentsservices`);

      const datas6 = await response.data.filter((info) => {
        return info.appointments.obs !== 'wait';
      });
      const datas4 = await datas6.filter((info) => {
        return (
          moment(info.appointments.date).format('DD/MM/YYYY') ===
          moment(value).format('DD/MM/YYYY')
        );
      });

      setAppointments(datas4);
    }
  }
  async function changeDate(date, ids) {
    setLoading(true);
    const response = await api.put('appointments', {
      id: ids,
      date: subHours(new Date(date), 3),
    });
    if (response.status === 200) {
      toast.success('Agendamento remarcado!');
      setLoading(false);
    } else {
      toast.error('Erro ao remarcar tente novamente!');
      setLoading(false);
    }
    setReload(response);
  }

  async function changeToAttendance(id) {
    if (values.doctor !== undefined) {
      setLoading(true);
      const response = await api.put('appointments', {
        id,
        doctor_id: values.doctor,
        obs: 'attendance',
      });
      if (response.status === 200) {
        toast.success('Triagem finalizada com sucesso!');
        setReload(response);
        setLoading(false);
      } else {
        toast.error('Erro ao finalizar a triagem!');
        setReload(response);
        setLoading(false);
      }
    } else {
      toast.error('Escolha um médico para a consulta!');
    }
  }

  async function changeToApacRequest(id) {
    if (values.doctor !== ' ') {
      setLoading(true);
      const response = await api.put('appointments', {
        id,
        doctor_id: values.doctor,
        obs: 'apac',
        status_key: 'Fazer pedido',
      });
      if (response.status === 200) {
        toast.success('Atendimento finalizada com sucesso!');
        setReload(response);
        setLoading(false);
      } else {
        toast.error('Erro ao finalizar o Atendimento!');
        setReload(response);
        setLoading(false);
      }
    } else {
      toast.error('Escolha um médico para a consulta!');
    }
  }

  async function updateJustify() {
    setLoading(true);
    const response = await api.put('appointments', {
      id: values.id,
      description: values.description,
      cid10p: values.cid10p,
      cid10s: values.cid10s,
      cid10ac: values.cid10ac,
      obsj: values.obsj,
    });
    if (response.status === 200) {
      toast.success('Justificativa atualizada com sucesso!');
      handleClose6();
      setUserAppointment({
        ...userAppointment,
        description: response.data.description,
        cid10p: response.data.cid10p,
        cid10s: response.data.cid10s,
        cid10ac: response.data.cid10ac,
        obsj: response.data.obsj,
      });
      setReload(response);
      setLoading(false);
    } else {
      toast.error('Erro ao atualizar justificativa!');
      setLoading(false);
    }
  }

  function getJustificationValue(valuess) {
    setValues({
      ...values,
      id: userAppointment.id,
      description: valuess.description,
      cid10p: valuess.cid10p,
      cid10s: valuess.cid10s,
      cid10ac: valuess.cid10ac,
      obsj: valuess.obs,
    });
  }

  function handleUpdateEnvironmentVariable(_id, field, valuess) {
    const environmentVariableslocal = [...environmentVariables];
    const variableToUpdate = environmentVariableslocal.find(
      (variable) => variable._id === _id
    );
    variableToUpdate[field] = valuess;
    setEnvironmentVariables(environmentVariableslocal);
  }

  function handleDeleteEnvironmentVariable(_id) {
    const environmentVariablesLocal = [...environmentVariables].filter(
      (variable) => variable._id !== _id
    );
    setEnvironmentVariables(environmentVariablesLocal);
  }

  function addEnvironmentVariable() {
    const environmentVariablesLocal = [...environmentVariables];
    environmentVariablesLocal.push({
      _id: cont,
      servicechoose: '',
      qtd: '',
    });
    setCont(cont + 1);
    setEnvironmentVariables(environmentVariablesLocal);
  }
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  async function appointmentServiceUpdate() {
    setLoading(true);
    let response2 = [];
    for (let i = 0; i < environmentVariables.length; i += 1) {
      response2 = await api.put('appointmentsservices', {
        id: allAppointmentServiceId.id,
        service_id: Number(environmentVariables[i].servicechoose),
      });
      if (response2.status === 200) {
        toast.success('Serviço adicionado com sucesso!');
        setLoad(!load);
        const response = await api.get('appointmentsservices');
        const datas = await response.data.filter((info) => {
          return info.appointment_id === userAppointment.id;
        });
        setAppointmentServices(datas);
        handleClose5();
        setLoading(false);
      } else {
        toast.error('Erro ao adicionar serviço!');
        setLoading(false);
      }
    }
  }

  return (
    <Page className={classes.root} title="Consultar Agendamento">
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Editar informações do cliente"
                title="Clientes"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Nome"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Nome Vida"
                      name="namefantasy"
                      value={values.namefantasy}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Nome da mãe"
                      name="nmother"
                      value={values.nmother}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Nome do responsável"
                      name="nresponsible"
                      value={values.nresponsible}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {/* <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      label="CPF"
                      name="cpfcnpj"
                      value={values.cpfcnpj}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      label="Registro G. (RG)"
                      name="rg"
                      value={values.rg}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid> */}
                  <Grid item md={10} xs={12}>
                    <TextField
                      fullWidth
                      label="Cartão nacional de saúde (CNS)"
                      name="cns"
                      value={values.cns}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label="CEAP"
                      name="ceap"
                      value={values.ceap}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label="DDD"
                      name="ddd"
                      value={values.ddd}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Telefone fixo"
                      name="phoneone"
                      value={values.phoneone}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Telefone celular"
                      name="phonetwo"
                      value={values.phonetwo}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={ptLocale}
                    >
                      <DatePicker
                        id="date-picker-dialog"
                        label="Nascimento"
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        value={selectedDate}
                        onChange={handleDateChange}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Sexo"
                      name="male"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.male}
                      variant="outlined"
                    >
                      {sex.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Raça/Cor"
                      name="breed"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.breed}
                      variant="outlined"
                    >
                      {color.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Etinia"
                      name="ethnicity"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.ethnicity}
                      variant="outlined"
                    >
                      {ethnicity.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={2} />
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  onClick={sendData}
                  color="primary"
                  variant="contained"
                >
                  {loading ? 'Carregando...' : 'Atualizar'}
                </ButtonUpdate>
              </Box>
            </Card>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open2}
        onClose={handleClose2}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open2}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Editar informações de endereço do cliente"
                title="Endereço"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={5} xs={12}>
                    <TextField
                      fullWidth
                      label="CEP"
                      name="cep"
                      value={values.cep}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <ButtonCPF
                      onClick={checkCep}
                      color="primary"
                      variant="contained"
                    >
                      <Icon style={{ color: 'white' }}>autorenew</Icon>
                    </ButtonCPF>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      label="Numero"
                      name="number"
                      value={values.number}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label="UF"
                      name="uf"
                      value={values.uf}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label="CÓD. IBGE MUNICÍPIO"
                      name="cibge"
                      value={values.cibge}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <TextField
                      fullWidth
                      label="Rua"
                      name="street"
                      value={values.street}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <TextField
                      fullWidth
                      label="Cidade"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                {button ? (
                  <ButtonUpdate
                    onClick={updateAddress}
                    color="primary"
                    variant="contained"
                  >
                    {loading ? 'Carregando...' : 'Atualizar'}
                  </ButtonUpdate>
                ) : (
                  <ButtonUpdate
                    onClick={sendAddress}
                    color="primary"
                    variant="contained"
                  >
                    {loading ? 'Carregando...' : 'Publicar'}
                  </ButtonUpdate>
                )}
              </Box>
            </Card>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open3}
        onClose={handleClose3}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open3}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader={
                  !status
                    ? 'editar informações do usuário para triagem'
                    : 'editar informações do usuário para atendimento'
                }
                title={!status ? 'Triagem' : 'Atendimento'}
              />
              <Divider />
              <CardContent>
                <Card className={clsx(classes.root2)}>
                  <PerfectScrollbar>
                    <Box minWidth={1050} maxHeight={500}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>Nome vida</TableCell>
                            <TableCell>CNS</TableCell>
                            <TableCell>Telefone</TableCell>
                            {!status ? null : <TableCell>Médico</TableCell>}

                            <TableCell>Informações</TableCell>
                            <TableCell>Endereço</TableCell>
                            <TableCell>Serviços</TableCell>
                            <TableCell>Justificativa</TableCell>

                            {!status ? (
                              <TableCell>Data para atendimento</TableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            hover
                            key={userAppointment && userAppointment.user.id}
                          >
                            <TableCell>
                              <Box alignItems="center" display="flex">
                                <Avatar
                                  className={classes.avatar}
                                  src={
                                    userAppointment &&
                                    userAppointment.user.avatarUrl
                                  }
                                >
                                  {getInitials(
                                    userAppointment && userAppointment.user.name
                                  )}
                                </Avatar>
                                <Typography color="textPrimary" variant="body1">
                                  {userAppointment && userAppointment.user.name}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              {userAppointment &&
                                userAppointment.user.namefantasy}
                            </TableCell>
                            <TableCell>
                              {userAppointment && userAppointment.user.cns}
                            </TableCell>
                            <TableCell>
                              {userAppointment && userAppointment.user.phoneone}
                            </TableCell>
                            {!status ? null : (
                              <TableCell>
                                {userAppointment && userAppointment.doctor.name}
                              </TableCell>
                            )}

                            <TableCell>
                              <ButtonUpdate
                                onClick={() => handleOpen(userAppointment.user)}
                                color="alert"
                                variant="contained"
                              >
                                Informações
                              </ButtonUpdate>
                            </TableCell>
                            <TableCell>
                              <ButtonUpdate
                                onClick={() =>
                                  handleOpen2(userAppointment.user)
                                }
                                color="alert"
                                variant="contained"
                              >
                                Endereço
                              </ButtonUpdate>
                            </TableCell>
                            <TableCell>
                              <ButtonUpdate
                                onClick={() => handleOpen4(userAppointment.id)}
                                color="alert"
                                variant="contained"
                              >
                                Serviços
                              </ButtonUpdate>
                            </TableCell>
                            <TableCell>
                              <ButtonUpdate
                                onClick={() => handleOpen6(userAppointment)}
                                color="alert"
                                variant="contained"
                              >
                                Justificativa
                              </ButtonUpdate>
                            </TableCell>
                            {!status ? (
                              <TableCell>
                                <MuiPickersUtilsProvider
                                  utils={DateFnsUtils}
                                  locale={ptLocale}
                                >
                                  <DatePicker
                                    id="date-picker-dialog"
                                    ampm={false}
                                    label="Data"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    value={userAppointment.date}
                                    onChange={(date) =>
                                      changeDate(date, userAppointment.id)
                                    }
                                  />
                                </MuiPickersUtilsProvider>
                              </TableCell>
                            ) : null}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Médico"
                    name="doctor"
                    onChange={handleChange}
                    select
                    style={{ marginTop: 20 }}
                    SelectProps={{ native: true }}
                    value={values.doctor}
                    variant="outlined"
                  >
                    {allDoctors.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </CardContent>
              <Divider />

              {!status ? (
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <ButtonUpdate
                    onClick={() => changeToAttendance(userAppointment.id)}
                    color="primary"
                    variant="contained"
                  >
                    {loading ? 'Carregando...' : 'Finalizar triagem'}
                  </ButtonUpdate>
                </Box>
              ) : (
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <ButtonUpdate
                    onClick={() => changeToApacRequest(userAppointment.id)}
                    color="primary"
                    variant="contained"
                  >
                    {loading ? 'Carregando...' : 'Finalizar Atendimento'}
                  </ButtonUpdate>
                </Box>
              )}
            </Card>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open4}
        onClose={handleClose4}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open4}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="adicionar ou remover serviços"
                title="Serviços"
              />
              <Divider />
              <CardContent>
                <PerfectScrollbar>
                  <Box maxHeight={600}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Código</TableCell>
                          <TableCell>Nome</TableCell>
                          <TableCell>Qtd</TableCell>
                          <TableCell>Remover</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {appointmentServices &&
                          appointmentServices.map((order) => (
                            <TableRow hover key={order && order.id}>
                              <TableCell>
                                {order.services === null
                                  ? ' '
                                  : order.services.code}
                              </TableCell>
                              <TableCell>
                                {order.services === null
                                  ? ' '
                                  : order.services.description}
                              </TableCell>
                              <TableCell>
                                {order.services === null
                                  ? ' '
                                  : order.services.qtd}
                              </TableCell>
                              <TableCell>
                                {order.services === null ? (
                                  ' '
                                ) : (
                                  <ButtonQuit
                                    onClick={() => deleteService(order.id)}
                                    color="alert"
                                    variant="contained"
                                  >
                                    {loading ? 'Carregando...' : 'Remover'}
                                  </ButtonQuit>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Box>
                </PerfectScrollbar>
              </CardContent>
              <Card>
                <Box display="flex" justifyContent="flex-end" p={3}>
                  <Button
                    color="segundary"
                    onClick={handleOpen5}
                    size="small"
                    variant="text"
                  >
                    Adicionar Serviço
                  </Button>
                </Box>
              </Card>
            </Card>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open5}
        onClose={handleClose5}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open5}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Adicionar serviços ao agendamento"
                title="Serviços"
              />
              <Divider />
              <CardContent width={900}>
                {environmentVariables.map(({ _id, servicechoose, qtd }) => (
                  <Grid container spacing={2}>
                    <Grid item md={5} xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        style={{ marginLeft: -9 }}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Selecione o serviço
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={servicechoose}
                          onChange={(event) =>
                            handleUpdateEnvironmentVariable(
                              _id,
                              'servicechoose',
                              event.target.value
                            )
                          }
                          label="Selecione o serviço"
                        >
                          {allContractServices &&
                            allContractServices.map((option) => (
                              <MenuItem
                                key={option.id}
                                value={option.services.id}
                              >
                                {option.services.description}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <TextField
                        fullWidth
                        label="Quantidade"
                        name="qtd"
                        value={qtd}
                        onChange={(event) =>
                          handleUpdateEnvironmentVariable(
                            _id,
                            'qtd',
                            event.target.value
                          )
                        }
                        style={{ marginTop: 9, height: 48, marginLeft: -15 }}
                        variant="outlined"
                        type="number"
                      />
                    </Grid>
                    <Grid display="flex" md={2} xs={12}>
                      <ButtonQuit
                        onClick={() => handleDeleteEnvironmentVariable(_id)}
                        color="alert"
                        style={{ marginTop: 18, height: 54 }}
                        variant="contained"
                      >
                        X
                      </ButtonQuit>
                    </Grid>
                  </Grid>
                ))}
              </CardContent>

              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  color="primary"
                  variant="contained"
                  onClick={addEnvironmentVariable}
                >
                  Adicionar serviço
                </ButtonUpdate>
              </Box>
              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  onClick={appointmentServiceUpdate}
                  color="primary"
                  variant="contained"
                >
                  {loading ? 'Carregando...' : 'Publicar'}
                </ButtonUpdate>
              </Box>
            </Card>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open6}
        onClose={handleClose6}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open6}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Editar justificativa do atendimento"
                title="Justificativa"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      style={{ marginLeft: -1 }}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Selecione a justificativa
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={value.description}
                        onChange={(event) =>
                          getJustificationValue(event.target.value)
                        }
                        label="Selecione a justificativa"
                      >
                        {allJustification &&
                          allJustification.map((option) => (
                            <MenuItem key={option.id} value={option}>
                              {option.description}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Descrição"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label="CID10P"
                      name="cid10p"
                      value={values.cid10p}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label="CID10S"
                      name="cid10s"
                      value={values.cid10s}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label="CID10AC"
                      name="cid10ac"
                      value={values.cid10ac}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Observação"
                      name="obsj"
                      value={values.obsj}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  onClick={updateJustify}
                  color="primary"
                  variant="contained"
                >
                  {loading ? 'Carregando...' : 'Atualizar'}
                </ButtonUpdate>
              </Box>
            </Card>
          </div>
        </Fade>
      </Modal>
      <Container>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ContainerCalendar>
            <Calendar
              onActiveStartDateChange={({ activeStartDate }) =>
                onChange(activeStartDate)
              }
              onClickMonth={onChange}
              onClickYear={onChange}
              onClickWeekNumber={onChange}
              onChange={onChange}
              tileClassName={({ date }) => {
                if (
                  dates.find(
                    (x) =>
                      moment(x).format('DD-MM-YYYY') ===
                      moment(date).format('DD-MM-YYYY')
                  )
                ) {
                  return 'highlight';
                }
              }}
              value={value}
            />
          </ContainerCalendar>

          <div style={{ flexDirection: 'row', height: 200 }}>
            <Box mt={5}>
              <ReactSearchAutocomplete
                items={clients}
                onSelect={handleOnChange}
                onSearch={handleOnChange}
                placeholder="Buscar clientes"
                maxResults={2}
                styling={{
                  borderRadius: '5px',
                  height: '60px',
                  hoverBackgroundColor: '#fff',
                }}
              />
            </Box>
            <ul>
              {appointments.map((appointment) => (
                <div style={{ flexDirection: 'row' }}>
                  <Time key={appointment.id} available={appointment.obs}>
                    <div style={{ justifyContent: 'space-between' }}>
                      <div style={{ flexDirection: 'column', width: 250 }}>
                        <strong>{appointment.appointments.user.name}</strong>
                        <span>
                          {appointment.services === null
                            ? ' '
                            : appointment.services.description}
                        </span>

                        {/* <span>CNS: {appointment.user.cns}</span>
                        {appointment.doctor === null ? (
                          <span>MÉDICO:</span>
                        ) : (
                          <span>MÉDICO: {appointment.doctor.name}</span>
                        )} */}
                      </div>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={ptLocale}
                      >
                        <DatePicker
                          id="date-picker-dialog"
                          ampm={false}
                          label="Data"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          style={{ marginLeft: 35 }}
                          value={appointment.appointments.date}
                          onChange={(date) =>
                            changeDate(date, appointment.appointments.id)
                          }
                        />
                      </MuiPickersUtilsProvider>
                      {appointment &&
                      appointment.appointments.obs === 'screening' ? (
                        <div
                          style={{
                            width: 150,
                            borderRadius: 5,
                            height: 52,
                            marginLeft: '2%',
                            background: '#4285F4',
                            alignSelf: 'flex-end',
                            justifyContent: 'center',
                          }}
                        >
                          <ConfirmationButton
                            onClick={() =>
                              handleOpen3(
                                appointment.appointments,
                                false,
                                appointment
                              )
                            }
                          >
                            TRIAGEM
                          </ConfirmationButton>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: 150,
                            height: 52,
                            borderRadius: 5,
                            marginLeft: '2%',
                            background: '#0b7269',
                            alignSelf: 'flex-end',
                            justifyContent: 'center',
                          }}
                        >
                          <ConfirmationButton
                            onClick={() =>
                              handleOpen3(
                                appointment.appointments,
                                true,
                                appointment
                              )
                            }
                          >
                            ATENDIMENTO
                          </ConfirmationButton>
                        </div>
                      )}

                      {appointment &&
                      appointment.appointments.obs !== 'apac' ? (
                        <div
                          style={{
                            width: 150,
                            borderRadius: 5,
                            height: 52,
                            marginLeft: '2%',
                            background: '#E53934',
                            alignSelf: 'flex-end',
                            justifyContent: 'center',
                          }}
                        >
                          <ConfirmationButton
                            onClick={() =>
                              removeAppointment(appointment.appointments.id)
                            }
                          >
                            REMOVER
                          </ConfirmationButton>
                        </div>
                      ) : null}
                    </div>
                  </Time>
                </div>
              ))}
            </ul>
          </div>
        </MuiPickersUtilsProvider>
      </Container>
    </Page>
  );
}

export default ShowAppointments;
