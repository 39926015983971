/* eslint-disable react/prop-types */
/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { toast } from 'react-toastify';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
} from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 50,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  root2: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '43%',
  },
  helper: {
    padding: theme.spacing(1, 2),
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const ButtonUpdate = styled(Button)({
  background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 48,
  width: '100%',
  padding: '0 30px',
});

const ButtonQuit = styled(Button)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 56,
  width: '95%',
  marginTop: 24,
});

export default function DetailedAccordion({ setUpdate, update }) {
  const classes = useStyles();
  const [environmentVariables, setEnvironmentVariables] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allDoctors, setAllDoctors] = useState([]);
  const [allContractServices, setAllContractServices] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [user, setUser] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [cont, setCont] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadUsers() {
      const response = await api.get(`users`);
      const datas = await response.data.filter((info) => {
        return info.ceap !== null;
      });
      setAllUsers(datas);
    }
    async function loadContractServices() {
      const response = await api.get(`allContractServices`);
      setAllContractServices(response.data);
    }

    async function loadDoctors() {
      const response = await api.get(`alldoctors`);
      setAllDoctors(response.data);
    }

    loadUsers();
    loadContractServices();
    loadDoctors();
  }, [update]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  async function handleOnSelect(item) {
    setUser(item.id);
  }

  function handleUpdateEnvironmentVariable(_id, field, value) {
    const environmentVariableslocal = [...environmentVariables];
    const variableToUpdate = environmentVariableslocal.find(
      (variable) => variable._id === _id
    );
    variableToUpdate[field] = value;
    setEnvironmentVariables(environmentVariableslocal);
  }

  function handleDeleteEnvironmentVariable(_id) {
    const environmentVariablesLocal = [...environmentVariables].filter(
      (variable) => variable._id !== _id
    );
    setEnvironmentVariables(environmentVariablesLocal);
  }

  function addEnvironmentVariable() {
    const environmentVariablesLocal = [...environmentVariables];
    environmentVariablesLocal.push({
      _id: cont,
      servicechoose: '',
      qtd: '',
    });
    setCont(cont + 1);
    setEnvironmentVariables(environmentVariablesLocal);
  }

  async function CreateContactAppointmet() {
    if (environmentVariables.length !== 0) {
      setLoading(true);
      const response = await api.post('appointments', {
        user_id: Number(user),
        date: selectedDate,
        obs: 'attendance',
        appointments_status: true,
        doctor_id: doctor,
      });
      if (response.status === 200) {
        let response2 = [];
        for (let i = 0; i < environmentVariables.length; i += 1) {
          response2 = await api.post('appointmentsservices', {
            service_id: Number(environmentVariables[i].servicechoose),
            appointment_id: Number(response.data.id),
            qtd: Number(environmentVariables[i].qtd),
          });
          if (response2.status === 200) {
            toast.success('Usuario adicionado para atendimento com sucesso!');
            setUpdate(!update);
            setLoading(false);
          }
        }
      } else {
        toast.error('Erro ao adicionar usuario a lista de espera');
        setLoading(false);
        setUpdate(!update);
      }
    } else {
      toast.error('Preencha algum serviço');
      setUpdate(!update);
    }
  }
  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Atendimento</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              Agende um atendimento para o paciente
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Card className={classes.root2}>
            <CardHeader
              subheader="Agendar atendimento para paciente"
              title="Atendimento"
            />
            <Divider />
            {environmentVariables.map(({ _id, servicechoose, qtd }) => (
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item md={4} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Selecione o serviço
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={servicechoose}
                        onChange={(event) =>
                          handleUpdateEnvironmentVariable(
                            _id,
                            'servicechoose',
                            event.target.value
                          )
                        }
                        label="Selecione o serviço"
                      >
                        {allContractServices &&
                          allContractServices.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.services.id}
                            >
                              {option.services.description}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Quantidade"
                      name="qtd"
                      value={qtd}
                      style={{ marginTop: 9, height: 48 }}
                      onChange={(event) =>
                        handleUpdateEnvironmentVariable(
                          _id,
                          'qtd',
                          event.target.value
                        )
                      }
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid display="flex" md={4} xs={12} p={1.8}>
                    <ButtonQuit
                      onClick={() => handleDeleteEnvironmentVariable(_id)}
                      color="alert"
                      variant="contained"
                    >
                      X
                    </ButtonQuit>
                  </Grid>
                </Grid>
              </CardContent>
            ))}
            <Divider />
            <Grid container spacing={4}>
              <Divider />
              <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                  <Box pb={12} pt={3} p={3}>
                    <ReactSearchAutocomplete
                      items={allUsers}
                      onSelect={handleOnSelect}
                      onSearch={handleOnSelect}
                      placeholder="Buscar clientes"
                      maxResults={2}
                      styles={{
                        listView: {
                          position: 'absolute',
                          backgroundColor: '#FFF',
                        },
                      }}
                      styling={{
                        borderRadius: '5px',
                        height: '60px',
                        hoverBackgroundColor: '#fff',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Divider />

              <Grid item md={10} xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Selecione o médico
                  </InputLabel>
                  <Select
                    label="Selecione o médico"
                    onChange={(event) => setDoctor(event.target.value)}
                    value={doctor}
                  >
                    {allDoctors &&
                      allDoctors.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                  <DatePicker
                    id="date-picker-dialog"
                    ampm={false}
                    style={{ marginTop: 9 }}
                    label="Data do atendimento"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <ButtonUpdate
                color="primary"
                variant="contained"
                onClick={addEnvironmentVariable}
              >
                Adicionar serviço
              </ButtonUpdate>
            </Box>
            <Divider />

            <Box display="flex" justifyContent="flex-end" p={2}>
              <ButtonUpdate
                color="primary"
                variant="contained"
                onClick={CreateContactAppointmet}
              >
                {loading ? 'Carregando...' : 'Agendar atendimneto de paciente'}
              </ButtonUpdate>
            </Box>
          </Card>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
