/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { Form, Input } from '@rocketseat/unform';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneIcon from '@material-ui/icons/Phone';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Grid,
  CardContent,
} from '@material-ui/core';

import Backdrop from '@material-ui/core/Backdrop';

import Modal from '@material-ui/core/Modal';
import { useSpring, animated } from 'react-spring/web.cjs';
import api from '../../../../services/api';
import {
  ThirdContainer,
  SubtitleTwo,
  Title,
  SecondContainer,
  Subtitle,
  Container,
  ImageContainer,
  SubtitleButton,
  LinkEmail,
  Moda,
} from './styles';

const useStyles = makeStyles(() => ({
  root: {},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export default function start() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [info, setInfo] = useState([]);

  async function handleSubmit({ cns }) {
    setLoading(true);
    const response = await api.get(`usersLandingPage`);
    const datas = await response.data.filter((info) => {
      return info.appointments.obs === 'wait';
    });
    const dataInfo = datas.map(function (item, index) {
      return {
        index: index + 1,
        name: item.appointments.user.name,
        cns: item.appointments.user.cns,
        code: item.services.code,
        service: item.services.description,
        id: item.id,
      };
    });
    const datas2 = await dataInfo.filter((infos) => {
      return infos.cns === cns;
    });
    setOpen(true);
    setInfo(datas2);
    setLoading(false);
  }
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Container>
      <Moda
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Posição da lista de espera"
                title="Lista de espera"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Posição</TableCell>
                          <TableCell>Nome</TableCell>
                          <TableCell>Serviço</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {info.map((order) => (
                          <TableRow hover key={order.id}>
                            <TableCell>{order.index}</TableCell>
                            <TableCell>{order.name}</TableCell>
                            <TableCell>{order.service}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                {/* <ButtonUpdate
                  onClick={updateData}
                  color="primary"
                  variant="contained"
                >
                  {loading ? 'Carregando...' : 'Atualizar'}
                </ButtonUpdate> */}
              </Box>
            </Card>
          </div>
        </Fade>
      </Moda>
      <SecondContainer>
        <Title>
          Realize aqui o seu cadastro para atendimento SUS na Rede Climed
          Brasil. Temos parceria com algumas clínicas para atendimento SUS aos
          residentes de Salvador/ Bahia.
        </Title>
        <Subtitle>
          Este site foi criado para facilitar o cadastro dos pacientes para os
          serviços ofertados na rede.
        </Subtitle>
        <ThirdContainer>
          <SubtitleTwo>
            Insira o número do seu cartão SUS para saber posição na fila do
            serviço solicitado
          </SubtitleTwo>
        </ThirdContainer>
        <ThirdContainer>
          <Form onSubmit={handleSubmit}>
            <Input name="cns" placeholder="Digite seu cartão do sus" />
            <button type="submit">
              {loading ? 'Carregando...' : 'Enviar'}
            </button>
          </Form>
        </ThirdContainer>
        <ImageContainer>
          <button
            onClick={() =>
              window.open('https://api.whatsapp.com/send?phone=5571999103329')
            }
            type="submit"
          >
            <WhatsAppIcon
              style={{ fontSize: 60, marginBottom: 5, marginTop: 10 }}
            />
            <SubtitleButton>71.99910-3329</SubtitleButton>
          </button>
          <button type="submit">
            <PhoneIcon
              style={{ fontSize: 60, marginBottom: 5, marginTop: 10 }}
            />
            <SubtitleButton>71.3032-1234</SubtitleButton>
          </button>
        </ImageContainer>
      </SecondContainer>

      <ThirdContainer>
        <LinkEmail href="mailto:redeclimedbrasil@climedbrasil.com.br?subject=Duvida%20">
          redeclimedbrasil@climedbrasil.com.br
        </LinkEmail>
      </ThirdContainer>
    </Container>
  );
}
