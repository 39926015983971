/* eslint-disable no-await-in-loop */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  makeStyles,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  TextField,
  Modal,
  Backdrop,
} from '@material-ui/core';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { styled } from '@material-ui/core/styles';
import { useSpring, animated } from 'react-spring/web.cjs';
import { toast } from 'react-toastify';
import getInitials from '../../utils/getInitials';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: 3 },
  root2: { marginTop: 100 },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const ButtonUpdate = styled(Button)({
  background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
  border: 0,
  borderRadius: 3,

  marginLeft: -20,
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 40,
});

const ButtonQuit = styled(Button)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 55,
  width: 150,
});

const ButtonQuitDelete = styled(Button)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,

  marginLeft: -20,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 40,
});
const TextFieldEdit = styled(TextField)({
  border: 0,
  borderColor: '#FF8E53',
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(128, 210, 201 ,.3)',
  color: 'white',
});
const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const Results = ({ className, ...rest }) => {
  const classes = useStyles();
  const [doctor, setDoctors] = useState([]);
  const [alldoctor, setAllDoctor] = useState([]);
  const [doctorservices, setDoctorServices] = useState([]);
  const [updateid, setUpdateId] = useState();
  const [cont, setCont] = useState(1);
  const [doctorid, setDoctorId] = useState();

  const [environmentVariables, setEnvironmentVariables] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [services, setServices] = useState([]);

  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    city: '',
    neighborhood: '',
    uf: '',
    street: '',
  });
  useEffect(() => {
    async function loadClients() {
      const response = await api.get(`alldoctors`);
      setDoctors(response.data);
      setAllDoctor(response.data);
    }
    async function loadServices() {
      const response = await api.get(`allContractServices`);
      response.data.unshift({
        contracts: { name: 'Escolha' },
        services: { description: 'Escolha' },
      });
      setServices(response.data);
    }
    loadServices();
    loadClients();
  }, [load]);

  async function handleOnSelect(item) {
    if (item === '') {
      const response = await api.get(`alldoctors`);
      setDoctors(response.data);
    } else {
      const response = await api.get('alldoctors');
      const datas = await response.data.filter((info) => {
        return info.id === Number(item.id);
      });

      setDoctors(datas);
    }
  }

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleOpen = (customer) => {
    setValues({
      ...values,
      id: customer.id,
      name: customer.name,
      crm: customer.crm,
      specialty: customer.specialty,
      cpf: customer.cpf,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen2 = async (customer) => {
    setDoctorId(customer.id);
    const response = await api.get(`doctorServices/${customer.id}`);
    setDoctorServices(response.data);
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleOpen3 = async (customer) => {
    setUpdateId(customer.id);
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleOpen4 = () => {
    setOpen4(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  async function sendData() {
    setLoading(true);
    const response = await api.put('doctor', {
      id: values.id,
      name: values.name,
      crm: values.crm,
      specialty: values.specialty,
      cpf: values.cpf,
    });
    if (response.status === 200) {
      setLoad(!load);
      toast.success('Dados atualizados com sucesso!');
      handleClose();
      setLoading(false);
    } else {
      toast.error('Erro ao atualizar dados!');
      setLoading(false);
    }
  }

  async function updateServiceData() {
    setLoading(true);
    const response = await api.put('doctorServices', {
      id: updateid,
      quotas: values.quotas,
    });
    if (response.status === 200) {
      setLoad(!load);
      toast.success('Cotas atualizadas com sucesso!');
      handleClose3();
      handleClose2();
      setLoading(false);
    } else {
      toast.error('Erro ao atualizar cotas de serviço!');
      setLoading(false);
    }
  }
  async function deleteService(id) {
    setLoading(true);

    const response = await api.delete(`doctorServices/${Number(id)}`);
    if (response.status === 200) {
      toast.success('Serviço removido com sucesso');
      setLoad(!load);
      handleClose3();
      handleClose2();
      setLoading(false);
    } else {
      toast.error('Erro ao remover serviço tente novamente!');
      setLoading(false);
    }
  }

  function handleUpdateEnvironmentVariable(_id, field, value) {
    const environmentVariableslocal = [...environmentVariables];
    const variableToUpdate = environmentVariableslocal.find(
      (variable) => variable._id === _id
    );
    variableToUpdate[field] = value;
    setEnvironmentVariables(environmentVariableslocal);
  }

  function handleDeleteEnvironmentVariable(_id) {
    const environmentVariablesLocal = [...environmentVariables].filter(
      (variable) => variable._id !== _id
    );
    setEnvironmentVariables(environmentVariablesLocal);
  }

  function addEnvironmentVariable() {
    const environmentVariablesLocal = [...environmentVariables];
    environmentVariablesLocal.push({
      _id: cont,
      contractservice_id: '',
      quotas: '',
    });
    setCont(cont + 1);
    setEnvironmentVariables(environmentVariablesLocal);
  }

  async function sendServices() {
    setLoading(true);

    let response2 = [];
    for (let i = 0; i < environmentVariables.length; i += 1) {
      response2 = await api.post('doctorServices', {
        quotas: Number(environmentVariables[i].quotas),
        contractservice_id: Number(environmentVariables[i].contractservice_id),
        doctor_id: Number(doctorid),
      });
      if (response2.status === 200) {
        setLoad(!load);
        handleClose4();
        handleClose3();
        handleClose2();
        toast.success('Serviços adicionados com sucesso!');
        setLoading(false);
      }
    }
  }
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Editar informações do médico"
                title="Médico"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Nome"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Especialidade"
                      name="specialty"
                      value={values.specialty}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="CPF"
                      name="cpf"
                      value={values.cpf}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="CRM"
                      name="crm"
                      value={values.crm}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  onClick={sendData}
                  color="primary"
                  variant="contained"
                >
                  {loading ? 'Carregando...' : 'Atualizar'}
                </ButtonUpdate>
              </Box>
            </Card>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open3}
        onClose={handleClose3}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open3}>
          <div className={classes.paper}>
            <Card>
              <CardHeader subheader="Editar cotas do médico" title="Editar" />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Cotas do serviço"
                      name="quotas"
                      value={values.quotas}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  onClick={updateServiceData}
                  color="primary"
                  variant="contained"
                >
                  {loading ? 'Carregando...' : 'Atualizar'}
                </ButtonUpdate>
              </Box>
            </Card>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open2}
        onClose={handleClose2}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open2}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Editar serviços do médico"
                title="Serviços"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <PerfectScrollbar>
                    <Box maxHeight={600}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Código</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>Cotas</TableCell>
                            <TableCell>Editar</TableCell>
                            <TableCell>Remover</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {doctorservices.map((order) => (
                            <TableRow hover key={order && order.id}>
                              <TableCell>
                                {order && order.contractservice.services.code}
                              </TableCell>
                              <TableCell>
                                {order &&
                                  order.contractservice.services.description}
                              </TableCell>
                              <TableCell>{order && order.quotas}</TableCell>
                              <TableCell>
                                <ButtonUpdate
                                  color="alert"
                                  variant="contained"
                                  onClick={() => handleOpen3(order)}
                                >
                                  Editar
                                </ButtonUpdate>
                              </TableCell>
                              <TableCell>
                                <ButtonQuitDelete
                                  color="alert"
                                  variant="contained"
                                  onClick={() => deleteService(order.id)}
                                >
                                  {loading ? 'Carregando...' : 'Remover'}
                                </ButtonQuitDelete>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  color="primary"
                  variant="contained"
                  onClick={() => handleOpen4()}
                >
                  Adicionar
                </ButtonUpdate>
              </Box>
            </Card>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open4}
        onClose={handleClose4}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open4}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Adicionar serviços ao médico"
                title="Serviços"
              />
              <Divider />
              {environmentVariables.map(
                ({ _id, contractservice_id, quotas }) => (
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Selecione o serviço"
                          name="contractservice_id"
                          value={contractservice_id}
                          onChange={(event) =>
                            handleUpdateEnvironmentVariable(
                              _id,
                              'contractservice_id',
                              event.target.value
                            )
                          }
                          select
                          SelectProps={{ native: true }}
                          variant="outlined"
                        >
                          {services &&
                            services.map((option) => (
                              <option
                                key={option.services.id}
                                value={option.id}
                              >
                                Contrato:{option.contracts.name} / Serviço:
                                {option.services.description}
                              </option>
                            ))}
                        </TextField>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextFieldEdit
                          fullWidth
                          label="Quantidade"
                          name="quotas"
                          value={quotas}
                          onChange={(event) =>
                            handleUpdateEnvironmentVariable(
                              _id,
                              'quotas',
                              event.target.value
                            )
                          }
                          variant="outlined"
                          type="number"
                        />
                      </Grid>
                      <Box
                        display="flex"
                        md={3}
                        xs={12}
                        justifyContent="center"
                        p={1.8}
                      >
                        <ButtonQuit
                          onClick={() => handleDeleteEnvironmentVariable(_id)}
                          color="alert"
                          variant="contained"
                        >
                          X
                        </ButtonQuit>
                      </Box>
                    </Grid>
                  </CardContent>
                )
              )}
              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  color="primary"
                  variant="contained"
                  onClick={addEnvironmentVariable}
                >
                  Adicionar serviço
                </ButtonUpdate>
              </Box>
              <Divider />

              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  color="primary"
                  variant="contained"
                  onClick={sendServices}
                >
                  {loading ? 'Carregando...' : 'Adicionar Serviços'}
                </ButtonUpdate>
              </Box>
            </Card>
          </div>
        </Fade>
      </Modal>
      <Box mt={5}>
        <ReactSearchAutocomplete
          items={alldoctor}
          onSelect={handleOnSelect}
          onSearch={handleOnSelect}
          autoFocus
          placeholder="Buscar médicos"
          maxResults={2}
          styling={{
            borderRadius: '5px',
            height: '60px',
            hoverBackgroundColor: '#fff',
          }}
        />
      </Box>

      <Card className={clsx(classes.root2, className)} {...rest}>
        <PerfectScrollbar>
          <Box maxHeight={500}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>CPF</TableCell>
                  <TableCell>Especialidade</TableCell>
                  <TableCell>CRM</TableCell>
                  <TableCell>INFORMAÇÕES</TableCell>
                  <TableCell>SERVIÇOS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {doctor.map((customer) => (
                  <TableRow hover key={customer && customer.id}>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Avatar
                          className={classes.avatar}
                          src={customer && customer.avatarUrl}
                        >
                          {getInitials(customer && customer.name)}
                        </Avatar>
                        <Typography color="textPrimary" variant="body1">
                          {customer && customer.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{customer && customer.cpf}</TableCell>
                    <TableCell>{customer && customer.specialty}</TableCell>
                    <TableCell>{customer && customer.crm}</TableCell>
                    <TableCell>
                      <ButtonUpdate
                        onClick={() => handleOpen(customer)}
                        color="alert"
                        variant="contained"
                      >
                        Informações
                      </ButtonUpdate>
                    </TableCell>
                    <TableCell>
                      <ButtonUpdate
                        onClick={() => handleOpen2(customer)}
                        color="alert"
                        variant="contained"
                      >
                        Serviços
                      </ButtonUpdate>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
};

export default Results;
