import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { cpf, password, history } = payload;
    const response = yield call(api.post, 'sessions', {
      cpfcnpj: cpf,
      password,
    });

    const { token, user } = response.data;

    api.defaults.headers.Authorization = `Baerer ${token}`;

    yield put(signInSuccess(token, user));
    history.push('/dashboard');
  } catch (err) {
    toast.error('Falha na autenticação, verifique seu cpf ou senha');
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const {
      name,
      cpfcnpj,
      rg,
      cns,
      namefantasy,
      nmother,
      nresponsible,
      ceap,
      ddd,
      selectedDate,
      phoneone,
      phonetwo,
      male,
      breed,
      ethnicity,
      find,
      cep,
      street,
      number,
      city,
      neighborhood,
      uf,
      cibge,
      service_id,
    } = payload;

    yield call(api.post, 'usersLandingPage', {
      name,
      cpfcnpj,
      rg,
      cns,
      namefantasy,
      nmother,
      nresponsible,
      ceap,
      ddd,
      born: selectedDate,
      phoneone,
      phonetwo,
      male,
      breed,
      ethnicity,
      admin: false,
      service_id,
      cep,
      street,
      number,
      city,
      neighborhood,
      uf,
      cibge,
    });

    toast.success('Dados enviados com sucesso!');

    yield put(signFailure());
  } catch (err) {
    toast.error('Falha no cadastro verifique seus dados!');
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `Baerer ${token}`;
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST_LANDING', signUp),
]);
