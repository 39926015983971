/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useSpring, animated } from 'react-spring/web.cjs';

import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles,
  CardContent,
  Grid,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import api from '../../services/api';

const useStyles = makeStyles(() => ({
  root: {},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));
const TextFieldEdit = styled(TextField)({
  border: 0,
  borderColor: '#FF8E53',
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(128, 210, 201 ,.3)',
  color: 'white',
});

const ButtonUpdate = styled(Button)({
  background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 48,
  width: '100%',
  padding: '0 30px',
});
const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};
const ButtonQuit = styled(Button)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 40,
  width: 100,
});

const LatestOrders = ({ className, location, ...rest }) => {
  const classes = useStyles();
  const [services, setServices] = useState([]);
  const [del, setDel] = useState(false);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const [allservices, setAllServices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadContracts() {
      const response = await api.get(
        `contractServices/${location.state.id.id}`
      );
      setServices(response.data);
    }
    async function loadServices() {
      const response = await api.get(`allServices`);
      response.data.unshift({
        description: 'Escolha',
      });
      setAllServices(response.data);
    }
    loadServices();
    loadContracts();
  }, [del]);

  async function deleteService(id) {
    setLoading(true);
    const response = await api.delete(`contractServices/${id}`);

    if (response.status === 200) {
      const response3 = await api.put('contract', {
        id: services[0].contracts.id,
        quotas: services.length - 1,
      });
      if (response3.status === 200) {
        setDel(!del);
        toast.success('Serviço removido com sucesso');
        setLoading(false);
      } else {
        toast.error('Erro ao remover serviço tente novamente!');
        setLoading(false);
      }
    } else {
      toast.error('Erro ao remover servivço');
      setLoading(false);
    }
  }
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function sendData() {
    setLoading(true);
    const response = await api.post('contractServicesController', {
      contract_id: Number(location.state.id.id),
      service_id: Number(values.servicechoose),
      amount: Number(values.amount),
      uvalue: Number(values.uvalue),
    });
    if (response.status === 200) {
      toast.success('Serviço adicionado com sucesso');
      const response3 = await api.put('contract', {
        id: location.state.id.id,
        quotas: services.length + 1,
      });
      if (response3.status === 200) {
        setDel(!del);
        setOpen(false);
        toast.success('Serviço adicionado com sucesso e pronto para uso!');
        setLoading(false);
      } else {
        toast.error('Erro ao criar serviço tente novamente!');
        setLoading(false);
      }
    } else {
      toast.error('Erro ao adicionar servivço');
      setLoading(false);
    }
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Adicionar serviços ao contrato"
                title="Serviços"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Selecione o serviço"
                      name="servicechoose"
                      value={values.servicechoose}
                      select
                      onChange={handleChange}
                      SelectProps={{ native: true }}
                      variant="outlined"
                    >
                      {allservices &&
                        allservices.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.description}
                          </option>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextFieldEdit
                      fullWidth
                      label="Quantidade"
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextFieldEdit
                      fullWidth
                      label="Valor"
                      name="uvalue"
                      value={values.uvalue}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  onClick={sendData}
                  color="primary"
                  variant="contained"
                >
                  {loading ? 'Carregando...' : 'Adicionar serviço'}
                </ButtonUpdate>
              </Box>
            </Card>
          </div>
        </Fade>
      </Modal>
      <CardHeader title="Serviços do contrato" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell sortDirection="desc">
                  <Tooltip enterDelay={300} title="Sort">
                    <TableSortLabel active direction="desc">
                      Date
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>Quantidade</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Remover</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {services.map((order) => (
                <TableRow hover key={order.id}>
                  <TableCell>{order.services.code}</TableCell>
                  <TableCell>{order.services.description}</TableCell>
                  <TableCell>
                    {moment(order.services.createdAt).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>{order.amount}</TableCell>
                  <TableCell>{order.uvalue}</TableCell>
                  <TableCell>
                    <ButtonQuit
                      color="alert"
                      onClick={() => deleteService(order.id)}
                      variant="contained"
                    >
                      X
                    </ButtonQuit>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box display="flex" justifyContent="flex-end" p={4}>
          <Button
            onClick={handleOpen}
            color="segundary"
            size="small"
            variant="text"
          >
            Adicionar serviço
          </Button>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string,
  services: PropTypes.array,
  location: PropTypes.array,
};

export default LatestOrders;
