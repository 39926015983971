/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneIcon from '@material-ui/icons/Phone';
import {
  Container,
  Title,
  ContainerTwo,
  ImageContainer,
  SubtitleButton,
  ThirdContainer,
  LinkEmail,
} from './styles';
import Form from './FormClient';
import logo from '../../../../assets/logo-climed-brasilbranco.png_940x629.png';

export default function start() {
  return (
    <Container style={{ zIndex: 10 }}>
      <ContainerTwo>
        <Title>Dados do paciente:</Title>
        <Form />
        <ImageContainer>
          <button
            onClick={() =>
              window.open('https://api.whatsapp.com/send?phone=5571999103329')
            }
            type="submit"
          >
            <WhatsAppIcon
              style={{ fontSize: 60, marginBottom: 5, marginTop: 10 }}
            />
            <SubtitleButton>71.99910-3329</SubtitleButton>
          </button>
          <button type="submit">
            <PhoneIcon
              style={{ fontSize: 60, marginBottom: 5, marginTop: 10 }}
            />
            <SubtitleButton>71.3032-1234</SubtitleButton>
          </button>
        </ImageContainer>
        <ThirdContainer>
          <LinkEmail href="mailto:redeclimedbrasil@climedbrasil.com.br?subject=Duvida%20">
            redeclimedbrasil@climedbrasil.com.br
          </LinkEmail>
        </ThirdContainer>
        <img src={logo} />
      </ContainerTwo>
    </Container>
  );
}
