/* eslint-disable no-await-in-loop */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { parseISO, differenceInDays, addDays } from 'date-fns';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import {
  Box,
  Card,
  makeStyles,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  TextField,
  Modal,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
// import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useHistory } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { styled } from '@material-ui/core/styles';
import { useSpring, animated } from 'react-spring/web.cjs';
import ptLocale from 'date-fns/locale/pt';
import { toast } from 'react-toastify';
import getInitials from '../../utils/getInitials';
import api from '../../services/api';

const status_key_option = [
  {
    value: 'Fazer pedido',
    label: 'Fazer pedido',
  },
  {
    value: 'Pendente',
    label: 'Pendente',
  },
  {
    value: 'Autorizada',
    label: 'Autorizada',
  },
  {
    value: 'Confirmada',
    label: 'Confirmada',
  },
  {
    value: 'Indeferida',
    label: 'Indeferida',
  },
];

const status_key_option_search = [
  {
    value: ' ',
    label: 'Todos',
  },
  {
    value: 'Fazer pedido',
    label: 'Fazer pedido',
  },
  {
    value: 'Pendente',
    label: 'Pendente',
  },
  {
    value: 'Autorizada',
    label: 'Autorizada',
  },
  {
    value: 'Confirmada',
    label: 'Confirmada',
  },
  {
    value: 'Indeferida',
    label: 'Indeferida',
  },
];
const useStyles = makeStyles((theme) => ({
  root: { marginBottom: 3 },
  root2: { marginTop: 90 },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  formControl: {
    width: '100%',
  },
}));

const ButtonUpdate = styled(Button)({
  background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
  border: 0,
  borderRadius: 3,

  marginLeft: -20,
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 40,
});

// const ButtonQuit = styled(Button)({
//   background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
//   border: 0,
//   borderRadius: 3,
//   boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
//   color: 'white',
//   height: 40,
//   width: 100,
// });

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const Results = ({ className, ...rest }) => {
  const classes = useStyles();
  const [allAppointmentUser, setAllAppointmentUser] = useState([]);
  const [appointments, setAppointments] = useState([]);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [services, setServices] = useState([]);
  const [appointmentsServices, setAppointmentsServices] = useState([]);
  const [clients, setClients] = useState([]);
  const [allJustification, setAllJustification] = useState([]);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [load, setLoad] = useState(false);
  const [open6, setOpen6] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [values, setValues] = useState({
    city: '',
    neighborhood: '',
    uf: '',
    street: '',
  });
  const [onlyOne, setOnlyOne] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const history = useHistory();

  useEffect(() => {
    async function loadClients() {
      const response = await api.get(`appointments`);
      const datas = await response.data.filter((info) => {
        return info.obs === 'apac';
      });
      const servicelocal = datas.map(function (item) {
        return {
          name: item.user.name,
          id: item.user.id,
        };
      });
      const filteredArr = servicelocal.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        }
        return acc;
      }, []);
      setClients(filteredArr);
      setAppointments(datas);
      setAllAppointmentUser(datas);
    }
    async function loadServices() {
      const response = await api.get(`allContractServices`);
      response.data.unshift({
        contracts: { name: 'Escolha' },
        services: { description: 'Escolha' },
      });
      setServices(response.data);
    }
    // async function loadClientss() {
    //   const response = await api.get(`appointments`);
    //   const datas = await response.data.filter((info) => {
    //     return info.obs === 'apac';
    //   });

    // }
    async function loadJustification() {
      const response = await api.get(`justification`);
      setAllJustification(response.data);
    }

    // loadClientss();
    loadClients();
    loadServices();
    loadClients();
    loadJustification();
  }, [load]);

  async function handleOnSelect(item) {
    if (item === ' ') {
      const response = await api.get(`appointments`);
      const datas = await response.data.filter((info) => {
        return info.obs === 'apac';
      });
      setAppointments(datas);
    } else {
      const response = await api.get('appointments');
      const datas = await response.data.filter((info) => {
        return info.status_key === item;
      });
      setAppointments(datas);
      setOnlyOne(true);
    }
  }
  async function handleOnChange(item) {
    if (item.id !== undefined) {
      setLoadingData(true);
      const response = await api.post(`appointmentsByName`, { id: item.id });
      // const datas = await response.data.filter((info) => {
      //   return info.obs === 'apac';
      // });

      setOnlyOne(true);

      setAppointments(response.data);
      setLoadingData(false);
    } else {
      setLoadingData(false);

      // const response = await api.get('appointments');
      // const datas = await response.data.filter((info) => {
      //   return info.obs === 'apac';
      // });
      // setAppointments(datas);
      // setOnlyOne(false);
    }
  }
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleOpen = (customer) => {
    setValues({
      ...values,
      id: customer.id,
      status_key: customer.status_key,
      key: customer.key,
    });
    setSelectedDate(customer.key_date);
    setOpen(true);
  };

  const handleOpen6 = (customer) => {
    setValues({
      ...values,
      id: customer.id,
    });
    setOpen6(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen2 = async (customer) => {
    const response = await api.get(`appointmentsservices`);
    const datas = await response.data.filter((info) => {
      return info.appointment_id === customer.id;
    });
    setAppointmentsServices(datas);

    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  async function updateData() {
    setLoading(true);
    const response = await api.put('appointments', {
      id: values.id,
      status_key: values.status_key,
      key: values.key,
      key_date: selectedDate,
    });
    if (response.status === 200) {
      setLoad(!load);
      toast.success('Dados atualizados com sucesso!');
      handleClose();
      setLoading(false);
    } else {
      toast.error('Erro ao atualizar dados!');
      setLoading(false);
    }
  }

  const handleClose6 = () => {
    setOpen6(false);
  };
  async function updateJustify() {
    setLoading(true);
    const response = await api.put('appointments', {
      id: values.id,
      description: values.description,
      cid10p: values.cid10p,
      cid10s: values.cid10s,
      cid10ac: values.cid10ac,
      obsj: values.obsj,
    });
    if (response.status === 200) {
      const response2 = await api.get(`appointments`);
      const datas = await response2.data.filter((info) => {
        return info.obs === 'apac';
      });
      const datas2 = await datas.filter((info) => {
        return info.id === values.id;
      });
      setAppointments(datas2);
      setLoading(false);
      toast.success('Justificativa atualizada com sucesso!');
      handleClose6();
    } else {
      toast.error('Erro ao atualizar justificativa!');
      setLoading(false);
    }
  }

  function getJustificationValue(valuess) {
    setValues({
      ...values,
      id: values.id,
      description: valuess.description,
      cid10p: valuess.cid10p,
      cid10s: valuess.cid10s,
      cid10ac: valuess.cid10ac,
      obsj: valuess.obs,
    });
  }
  // async function deleteService(id) {
  //   const response = await api.delete(`appointmentsservices/${id}`);
  //   if (response.status === 200) {
  //     toast.success('Serviço removido com sucesso');
  //     const response2 = await api.get('appointmentsservices');
  //     const datas = await response2.data.filter((info) => {
  //       return info.appointment_id === id;
  //     });
  //     setAppointmentsServices(datas);
  //   } else {
  //     toast.error('Erro ao remover serviço tente novamente!');
  //   }
  // }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Editar informações do médico"
                title="Médico"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={8} xs={12}>
                    <TextField
                      fullWidth
                      label="Número da Key"
                      name="key"
                      value={values.key}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={ptLocale}
                    >
                      <DatePicker
                        ampm={false}
                        label="Data da chave"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        name="key_date"
                        value={selectedDate}
                        onChange={handleDateChange}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Status da chave
                      </InputLabel>
                      <Select
                        label="Selecione o status da chave"
                        onChange={handleChange}
                        value={values.status_key}
                        name="status_key"
                      >
                        {status_key_option &&
                          status_key_option.map((option) => (
                            <MenuItem key={option.id} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  onClick={updateData}
                  color="primary"
                  variant="contained"
                >
                  {loading ? 'Carregando...' : 'Atualizar'}
                </ButtonUpdate>
              </Box>
            </Card>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open2}
        onClose={handleClose2}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open2}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Editar informações de serviços"
                title="Serviços"
              />
              <Divider />
              <CardContent>
                <PerfectScrollbar>
                  <Box maxHeight={600}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Código</TableCell>
                          <TableCell>Nome</TableCell>
                          <TableCell>Quantidade</TableCell>
                          {/* <TableCell>Remover</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {appointmentsServices.map((order) => (
                          <TableRow hover key={order.id}>
                            <TableCell>{order.services.code}</TableCell>
                            <TableCell>{order.services.description}</TableCell>
                            <TableCell>{order.qtd}</TableCell>
                            {/* <TableCell>
                              <ButtonQuit
                                onClick={() => deleteService(order.id)}
                                color="alert"
                                variant="contained"
                              >
                                Remover
                              </ButtonQuit>
                            </TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </PerfectScrollbar>
              </CardContent>
              <Divider />
            </Card>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open6}
        onClose={handleClose6}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open6}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Editar justificativa do atendimento"
                title="Justificativa"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      style={{ marginLeft: -1 }}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Selecione a justificativa
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={values.description}
                        onChange={(event) =>
                          getJustificationValue(event.target.value)
                        }
                        label="Selecione a justificativa"
                      >
                        {allJustification &&
                          allJustification.map((option) => (
                            <MenuItem key={option.id} value={option}>
                              {option.description}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Descrição"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label="CID10P"
                      name="cid10p"
                      value={values.cid10p}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label="CID10S"
                      name="cid10s"
                      value={values.cid10s}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label="CID10AC"
                      name="cid10ac"
                      value={values.cid10ac}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Observação"
                      name="obsj"
                      value={values.obsj}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  onClick={updateJustify}
                  color="primary"
                  variant="contained"
                >
                  {loading ? 'Carregando...' : 'Atualizar'}
                </ButtonUpdate>
              </Box>
            </Card>
          </div>
        </Fade>
      </Modal>
      <Box mt={5}>
        {/* <ReactSearchAutocomplete
          items={allAppointmentUser}
          onSelect={handleOnSelect}
          onSearch={handleOnSelect}
          autoFocus
          placeholder="Buscar pacientes"
          maxResults={3}
          styling={{
            borderRadius: '5px',
            height: '60px',
            hoverBackgroundColor: '#fff',
          }}
        /> */}
        <Card>
          <Divider />
          <CardContent>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Status da chave
              </InputLabel>
              <Select
                label="Pesquise pelo o status da chave"
                onChange={(event) => handleOnSelect(event.target.value)}
                name="status_key"
              >
                {status_key_option_search &&
                  status_key_option_search.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </CardContent>

          <Divider />
        </Card>
        <Box mt={5}>
          <ReactSearchAutocomplete
            items={clients}
            onSelect={handleOnChange}
            onSearch={handleOnChange}
            placeholder="Buscar clientes"
            maxResults={2}
            styling={{
              borderRadius: '5px',
              height: '60px',
              hoverBackgroundColor: '#fff',
            }}
          />
        </Box>
      </Box>
      {!loadingData && (
        <Card className={clsx(classes.root2, className)} {...rest}>
          <PerfectScrollbar>
            <Box maxHeight={500}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>CNS</TableCell>
                    <TableCell>Médico</TableCell>
                    <TableCell>Status Apac</TableCell>
                    <TableCell>Périodo de validação</TableCell>
                    <TableCell>INFORMAÇÕES</TableCell>
                    <TableCell>SERVIÇOS</TableCell>
                    <TableCell>JUSTIFICATIVA</TableCell>
                    <TableCell>APAC</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {onlyOne ? (
                    <>
                      {appointments &&
                        appointments.map((customer) => (
                          <TableRow hover key={customer && customer.id}>
                            <TableCell>
                              <Box alignItems="center" display="flex">
                                <Avatar
                                  className={classes.avatar}
                                  src={customer && customer.avatarUrl}
                                >
                                  {getInitials(customer && customer.user.name)}
                                </Avatar>
                                <Typography color="textPrimary" variant="body1">
                                  {customer && customer.user.name}
                                </Typography>
                              </Box>
                            </TableCell>

                            <TableCell>
                              {customer && customer.user.cns}
                            </TableCell>
                            <TableCell>
                              {customer && customer.doctor.name}
                            </TableCell>
                            <TableCell>
                              {customer && customer.status_key}
                            </TableCell>
                            <TableCell>
                              {differenceInDays(
                                addDays(parseISO(customer.date), 90),
                                new Date()
                              ) <= 0
                                ? 'Expirado'
                                : differenceInDays(
                                    addDays(parseISO(customer.date), 90),
                                    new Date()
                                  )}{' '}
                              Dias
                            </TableCell>
                            <TableCell>
                              <ButtonUpdate
                                onClick={() => handleOpen(customer)}
                                color="alert"
                                variant="contained"
                              >
                                Informações
                              </ButtonUpdate>
                            </TableCell>
                            <TableCell>
                              <ButtonUpdate
                                onClick={() => handleOpen2(customer)}
                                color="alert"
                                variant="contained"
                              >
                                Serviços
                              </ButtonUpdate>
                            </TableCell>
                            <TableCell>
                              <ButtonUpdate
                                onClick={() => handleOpen6(customer)}
                                color="alert"
                                variant="contained"
                              >
                                Justificativa
                              </ButtonUpdate>
                            </TableCell>
                            <TableCell>
                              <ButtonUpdate
                                disabled={loading}
                                onClick={() =>
                                  history.push({
                                    pathname: '/printApac',
                                    state: { id: customer },
                                  })
                                }
                                color="alert"
                                variant="contained"
                              >
                                APAC
                              </ButtonUpdate>
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  ) : null}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
        </Card>
      )}
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
};

export default Results;
