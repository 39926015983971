import React, { useState, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { styled } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Background from '../../assets/modeloApac.jpeg';
import api from '../../services/api';

const sectionStyle = {
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${Background})`,
  height: 1350,
  width: 1000,
  marginTop: 10,
};

const ButtonUpdate = styled(Button)({
  background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
  border: 0,
  borderRadius: 3,
  marginTop: 30,
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 40,
});

export default function AuthLayout({ location }) {
  const componentRef = useRef();
  const [address, setAddress] = useState([]);
  const [appointmentServices, setAppointmentServices] = useState({
    qtd: ` `,
    services: { code: ` `, description: ` ` },
  });
  const [appointmentServices2, setAppointmentServices2] = useState({
    qtd: ` `,
    services: { code: ` `, description: ` ` },
  });
  const [appointmentServices3, setAppointmentServices3] = useState({
    qtd: ` `,
    services: { code: ` `, description: ` ` },
  });
  const [appointmentServices4, setAppointmentServices4] = useState({
    qtd: ` `,
    services: { code: ` `, description: ` ` },
  });
  const [appointmentServices5, setAppointmentServices5] = useState({
    qtd: ` `,
    services: { code: ` `, description: ` ` },
  });
  const [appointmentServices6, setAppointmentServices6] = useState({
    qtd: ` `,
    services: { code: ` `, description: ` ` },
  });
  const [userData, setUserData] = useState({
    created: { name: ` `, cnes: ` ` },
  });

  const profile = useSelector((state) => state.user.profile);

  useEffect(() => {
    async function loadAddress() {
      const response = await api.get(`address/${location.state.id.user_id}`);
      setAddress(response.data[0]);
    }
    async function loadAppointmentServices() {
      const response = await api.get(`appointmentsservices`);
      const datas = await response.data.filter((info) => {
        return info.appointment_id === location.state.id.id;
      });
      setAppointmentServices(datas[0]);
      if (datas[1] !== undefined) setAppointmentServices2(datas[1]);
      if (datas[2] !== undefined) setAppointmentServices3(datas[2]);
      if (datas[3] !== undefined) setAppointmentServices4(datas[3]);
      if (datas[4] !== undefined) setAppointmentServices5(datas[4]);
      if (datas[5] !== undefined) setAppointmentServices6(datas[5]);
    }
    async function loadUserData() {
      const response = await api.get(`usersAdmin/${profile.id}`);
      setUserData(response.data[0]);
    }

    loadUserData();
    loadAppointmentServices();
    loadAddress();
  }, []);
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <ReactToPrint
          trigger={() => (
            <ButtonUpdate type="submit">Imprimir Apac!</ButtonUpdate>
          )}
          content={() => componentRef.current}
        />
        <div style={sectionStyle} ref={componentRef}>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 120,
              paddingLeft: 60,
            }}
          >
            Climed Brasil
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 120,
              paddingLeft: 780,
            }}
          >
            9139915
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 187,
              paddingLeft: 60,
            }}
          >
            {location && location.state.id.user.name}
          </h1>
          {location && location.state.id.user.male ? (
            <h1
              style={{
                fontSize: 22,
                position: 'absolute',
                paddingTop: 188,
                paddingLeft: 710,
              }}
            >
              X
            </h1>
          ) : (
            <h1
              style={{
                fontSize: 22,
                position: 'absolute',
                paddingTop: 188,
                paddingLeft: 768,
              }}
            >
              X
            </h1>
          )}

          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 188,
              paddingLeft: 810,
            }}
          >
            {location && location.state.id.id}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 228,
              paddingLeft: 60,
            }}
          >
            {location && location.state.id.user.cns}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 228,
              paddingLeft: 535,
            }}
          >
            {location &&
              moment(location.state.id.user.born).format('DD/MM/YYYY')}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 228,
              paddingLeft: 690,
            }}
          >
            {location && location.state.id.user.breed}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 228,
              paddingLeft: 810,
            }}
          >
            {location && location.state.id.user.ethnicity}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 270,
              paddingLeft: 693,
            }}
          >
            {location && location.state.id.user.ddd}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 270,
              paddingLeft: 740,
            }}
          >
            {location && location.state.id.user.phoneone}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 315,
              paddingLeft: 693,
            }}
          >
            {location && location.state.id.user.ddd}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 310,
              paddingLeft: 740,
            }}
          >
            {location && location.state.id.user.phonetwo}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 270,
              paddingLeft: 60,
            }}
          >
            {location && location.state.id.user.nmother}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 310,
              paddingLeft: 60,
            }}
          >
            {location && location.state.id.user.nresponsible}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 345,
              paddingLeft: 60,
            }}
          >
            {address && address.street}, {address && address.number},{' '}
            {address && address.neighborhood}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 385,
              paddingLeft: 60,
            }}
          >
            {address && address.city}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 385,
              paddingLeft: 610,
            }}
          >
            {address && address.cibge}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 385,
              paddingLeft: 745,
            }}
          >
            {address && address.uf}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 385,
              paddingLeft: 795,
            }}
          >
            {address && address.cep}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 460,
              paddingLeft: 60,
            }}
          >
            {appointmentServices && appointmentServices.services.code}
          </h1>
          <h1
            style={{
              fontSize: 13,
              position: 'absolute',
              paddingTop: 460,
              paddingLeft: 370,
            }}
          >
            {appointmentServices && appointmentServices.services.description}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 460,
              paddingLeft: 860,
            }}
          >
            1
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 530,
              paddingLeft: 60,
            }}
          >
            {appointmentServices2 && appointmentServices2.services.code}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 530,
              paddingLeft: 370,
            }}
          >
            {appointmentServices2 && appointmentServices2.services.description}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 530,
              paddingLeft: 880,
            }}
          >
            {appointmentServices2 && appointmentServices2.qtd}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 575,
              paddingLeft: 60,
            }}
          >
            {appointmentServices3 && appointmentServices3.services.code}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 575,
              paddingLeft: 370,
            }}
          >
            {appointmentServices3 && appointmentServices3.services.description}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 575,
              paddingLeft: 880,
            }}
          >
            {appointmentServices3 && appointmentServices3.services.qtd}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 620,
              paddingLeft: 60,
            }}
          >
            {appointmentServices4 && appointmentServices4.services.code}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 620,
              paddingLeft: 370,
            }}
          >
            {appointmentServices4 && appointmentServices4.services.description}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 620,
              paddingLeft: 880,
            }}
          >
            {appointmentServices4 && appointmentServices4.qtd}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 665,
              paddingLeft: 60,
            }}
          >
            {appointmentServices5 && appointmentServices5.services.code}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 665,
              paddingLeft: 370,
            }}
          >
            {appointmentServices5 && appointmentServices5.services.description}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 665,
              paddingLeft: 880,
            }}
          >
            {appointmentServices5 && appointmentServices5.qtd}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 710,
              paddingLeft: 60,
            }}
          >
            {appointmentServices6 && appointmentServices6.services.code}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 710,
              paddingLeft: 370,
            }}
          >
            {appointmentServices6 && appointmentServices6.services.description}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 710,
              paddingLeft: 880,
            }}
          >
            {appointmentServices6 && appointmentServices6.qtd}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 790,
              paddingLeft: 60,
            }}
          >
            {location && location.state.id.description}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 790,
              paddingLeft: 580,
            }}
          >
            {location && location.state.id.cid10p}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 790,
              paddingLeft: 680,
            }}
          >
            {location && location.state.id.cid10s}
          </h1>

          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 790,
              paddingLeft: 790,
            }}
          >
            {location && location.state.id.cid10ac}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 840,
              paddingLeft: 60,
            }}
          >
            {location && location.state.id.obsj}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 1020,
              paddingLeft: 60,
            }}
          >
            {location && location.state.id.doctor.name}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 1020,
              paddingLeft: 520,
            }}
          >
            {/* {location && moment(location.state.id.date).format('DD/MM/YYYY')} */}
          </h1>
          {/* <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 1057,
              paddingLeft: 86,
            }}
          >
            X
          </h1> */}
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 1057,
              paddingLeft: 175,
            }}
          >
            X
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 1060,
              paddingLeft: 250,
            }}
          >
            {location && location.state.id.doctor.cpf}
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 1315,
              paddingLeft: 65,
            }}
          >
            Climed Brasil
          </h1>
          <h1
            style={{
              fontSize: 22,
              position: 'absolute',
              paddingTop: 1315,
              paddingLeft: 745,
            }}
          >
            9139915
          </h1>
        </div>
      </div>
    </>
  );
}
