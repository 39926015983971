/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { parseISO, differenceInDays, addDays } from 'date-fns';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end',
  },
  statuspedding: {
    background: `#ff9800`,
    color: `#FFF`,
  },
  statusaproved: {
    background: `#76ff03`,
    color: `#FFF`,
  },
  statusmakerequest: {
    background: `#1F88E5`,
    color: `#FFF`,
  },
  statusconfirmed: {
    background: `#8E25AA`,
    color: `#FFF`,
  },
  statusrejected: {
    background: `#ab003c`,
    color: `#FFF`,
  },
}));

const LatestOrders = ({ className, allAppointment, setApac, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title={setApac} />
      <Divider />

      <PerfectScrollbar style={{ height: 335 }}>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>APAC ID</TableCell>
                <TableCell>Paciente</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Período de validade</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allAppointment.map((order, index) => (
                <TableRow hover key={order.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{order.user.name}</TableCell>
                  <TableCell>
                    {moment(order.date).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {differenceInDays(
                      addDays(parseISO(order.date), 90),
                      new Date()
                    ) <= 0 ? (
                      <p>Tempo esgotado</p>
                    ) : (
                      <p>
                        {differenceInDays(
                          addDays(parseISO(order.date), 90),
                          new Date()
                        )}{' '}
                        Dias
                      </p>
                    )}
                  </TableCell>
                  {order && order.status_key === `Pendente` ? (
                    <TableCell>
                      <Chip
                        className={clsx(classes.statuspedding, className)}
                        label={order.status_key}
                        size="small"
                      />
                    </TableCell>
                  ) : null}
                  {order && order.status_key === `Autorizada` ? (
                    <TableCell>
                      <Chip
                        className={clsx(classes.statusaproved, className)}
                        label={order.status_key}
                        size="small"
                      />
                    </TableCell>
                  ) : null}
                  {order && order.status_key === `Fazer pedido` ? (
                    <TableCell>
                      <Chip
                        className={clsx(classes.statusmakerequest, className)}
                        label={order.status_key}
                        size="small"
                      />
                    </TableCell>
                  ) : null}
                  {order && order.status_key === `Confirmada` ? (
                    <TableCell>
                      <Chip
                        className={clsx(classes.statusconfirmed, className)}
                        label={order.status_key}
                        size="small"
                      />
                    </TableCell>
                  ) : null}
                  {order && order.status_key === `Indeferida` ? (
                    <TableCell>
                      <Chip
                        className={clsx(classes.statusrejected, className)}
                        label={order.status_key}
                        size="small"
                      />
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
          onClick={() => history.push('/consultarApac')}
        >
          Ver Todas
        </Button>
      </Box>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string,
  allAppointment: PropTypes.array,
  setApac: PropTypes.string,
};

export default LatestOrders;
