export const MenuItems = [
  {
    title: 'Consultar',
    path: '/showClients',
    cName: 'dropdown-link',
  },
  {
    title: 'Adicionar',
    path: '/AddClient',
    cName: 'dropdown-link',
  },
];
