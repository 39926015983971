import React from 'react';
import { Form, Input } from '@rocketseat/unform';
import { useHistory } from 'react-router-dom';

import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import logo from '../../assets/logo.png';
import { Content, Wrapper } from './styles';

import { signInRequest } from '../../store/modules/auth/actions';

const schema = Yup.object().shape({
  cpf: Yup.string().required('O cpf é obrigatório'),
  password: Yup.string().required('A Senha é obrigatória'),
});

function SignIn() {
  const loading = useSelector((state) => state.auth.loading);
  const history = useHistory();

  const dispatch = useDispatch();

  function handleSubmit({ cpf, password }) {
    dispatch(signInRequest(cpf, password, history));
  }

  return (
    <Page title="Login">
      <Wrapper>
        <Content>
          <img src={logo} height="250" width="280" alt="Climed" />
          <Form schema={schema} onSubmit={handleSubmit}>
            <Input name="cpf" placeholder="Seu Cpf" />
            <Input
              name="password"
              type="password"
              placeholder="Sua senha secreta"
            />

            <button type="submit">
              {loading ? 'Carregando...' : 'Acessar'}
            </button>
          </Form>
        </Content>
      </Wrapper>
    </Page>
  );
}
export default SignIn;
