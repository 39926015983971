/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  makeStyles,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  TextField,
  Modal,
  Backdrop,
  Icon,
} from '@material-ui/core';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import ceps from 'cep-promise';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { styled } from '@material-ui/core/styles';
import { useSpring, animated } from 'react-spring/web.cjs';
import { toast } from 'react-toastify';
import ptLocale from 'date-fns/locale/pt';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import getInitials from '../../utils/getInitials';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: 3 },
  root2: { marginTop: 100 },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});
const ButtonUpdate = styled(Button)({
  background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
  border: 0,
  borderRadius: 3,
  marginTop: 5,
  marginLeft: -30,
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 48,
});
const ButtonCPF = styled(Button)({
  background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
  border: 0,
  borderRadius: 3,
  marginTop: 5,
  width: '100%',
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 48,
});
const sex = [
  {
    value: 'Selecione',
    label: 'Selecione',
  },
  {
    value: false,
    label: 'Feminino',
  },
  {
    value: true,
    label: 'Masculino',
  },
];

const color = [
  {
    value: 'Selecione',
    label: 'Selecione',
  },
  {
    value: 'Preta',
    label: 'Preta',
  },
  {
    value: 'Branca',
    label: 'Branca',
  },
  {
    value: 'Parda',
    label: 'Parda',
  },
  {
    value: 'Amarela',
    label: 'Amarela',
  },
  {
    value: 'Indígena',
    label: 'Indígena',
  },
];
const ethnicity = [
  {
    value: 'Selecione',
    label: 'Selecione',
  },
  {
    value: 'Mulatos',
    label: 'Mulatos',
  },
  {
    value: 'Caboclos',
    label: 'Caboclos',
  },
  {
    value: 'Cafuzo',
    label: 'Cafuzo',
  },
  {
    value: 'Indígenas',
    label: 'Indígenas',
  },
  {
    value: 'Brancos',
    label: 'Brancos',
  },
  {
    value: 'Negros',
    label: 'Negros',
  },
];

const Results = ({ className, ...rest }) => {
  const classes = useStyles();
  const [clients, setClients] = useState([]);
  const [allclients, setAllClients] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [find, setFind] = useState(false);
  const [load, setLoad] = useState(false);
  const [button, setButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onlyOne, setOnlyOne] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loadingAllPage, setLoadingAllPage] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [values, setValues] = useState({
    city: '',
    neighborhood: '',
    uf: '',
    street: '',
  });
  useEffect(() => {
    setLoadingAllPage(true);
    async function loadClients() {
      const response = await api.get(`users`);
      setClients(response.data);
      setAllClients(response.data);
      setLoadingAllPage(false);
    }

    loadClients();
  }, [load]);
  async function handleOnSelect(item) {
    if (item.id !== undefined) {
      setLoadingData(true);
      const response = await api.post(`usersById`, {
        id: item.id,
      });

      setOnlyOne(true);
      setClients(response.data);
      setLoadingData(false);
    } else {
      setLoadingData(false);

      // setClients(allclients);
      // setOnlyOne(false);
    }
  }
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleOpen = (customer) => {
    setSelectedDate(customer.born);
    setValues({
      ...values,
      id: customer.id,
      name: customer.name,
      namefantasy: values.namefantasy,
      nmother: customer.nmother,
      nresponsible: customer.nresponsible,
      cpfcnpj: customer.cpfcnpj,
      rg: customer.rg,
      cns: customer.cns,
      ddd: customer.ddd,
      phoneone: customer.phoneone,
      phonetwo: customer.phonetwo,
      male: customer.male,
      breed: customer.breed,
      ethnicity: customer.ethnicity,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleOpen2 = async (customer) => {
    const response = await api.get(`address/${customer.id}`);
    if (response.data.length === 0) {
      setButton(false);
      setValues({
        ...values,
        id: customer.id,
        cep: ' ',
        street: ' ',
        number: ' ',
        city: ' ',
        neighborhood: ' ',
        uf: ' ',
        cibge: ' ',
      });
    } else {
      setButton(true);
      setValues({
        ...values,
        id: customer.id,
        cep: response.data[0].cep,
        street: response.data[0].street,
        number: response.data[0].number,
        city: response.data[0].city,
        neighborhood: response.data[0].neighborhood,
        uf: response.data[0].uf,
        cibge: response.data[0].cibge,
      });
    }
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  async function checkCep() {
    await ceps(values.cep)
      .then(async (res) => {
        setFind(true);
        await setValues({
          ...values,
          city: res.city,
          neighborhood: res.neighborhood,
          uf: res.state,
          street: res.street,
        });
      })
      .catch(() => {
        setFind(false);
        toast.error('Cep invalido!');
      });
  }
  async function sendData() {
    setLoading(true);
    const response = await api.put('usersAdmin', {
      id: values.id,
      name: values.name,
      namefantasy: values.namefantasy,
      nmother: values.nmother,
      nresponsible: values.nresponsible,
      ceap: values.ceap,
      cns: values.cns,
      ddd: values.ddd,
      born: selectedDate,
      phoneone: values.phoneone,
      phonetwo: values.phonetwo,
      male: values.male,
      breed: values.breed,
      ethnicity: values.ethnicity,
    });
    if (response.status === 200) {
      setLoad(!load);
      toast.success('Dados atualizados com sucesso!');
      handleClose();
      setLoading(false);
    } else {
      toast.error('Erro ao atualizar dados!');
      setLoading(false);
    }
  }
  async function updateAddress() {
    if (find === true) {
      setLoading(true);
      const response = await api.put('address', {
        id: values.id,
        cep: values.cep,
        street: values.street,
        number: values.number,
        city: values.city,
        neighborhood: values.neighborhood,
        uf: values.uf,
        cibge: values.cibge,
      });
      if (response.status === 200) {
        setLoad(!load);
        toast.success('Dados do endereço atualizados com sucesso!');
        handleClose();
        setLoading(false);
      } else {
        toast.error('Erro ao atualizar dados de endereço!');
        setLoading(false);
      }
    } else {
      toast.error('Pesquise antes o cep!');
      setLoading(false);
    }
  }
  async function sendAddress() {
    if (find === true) {
      setLoading(true);
      const response = await api.post('address', {
        id: values.id,
        cep: values.cep,
        street: values.street,
        number: values.number,
        city: values.city,
        neighborhood: values.neighborhood,
        cibge: values.cibge,
        uf: values.uf,
      });
      if (response.status === 200) {
        setLoad(!load);
        toast.success('Dados do endereço enviados com sucesso!');
        handleClose();
        setLoading(false);
      } else {
        toast.error('Erro ao enviar dados de endereço!');
        setLoading(false);
      }
    } else {
      toast.error('Pesquise antes o cep!');
      setLoading(false);
    }
  }
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Editar informações do cliente"
                title="Clientes"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Nome"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Nome Vida"
                      name="namefantasy"
                      value={values.namefantasy}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Nome da mãe"
                      name="nmother"
                      value={values.nmother}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Nome do responsável"
                      name="nresponsible"
                      value={values.nresponsible}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {/* <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      label="CPF"
                      name="cpfcnpj"
                      value={values.cpfcnpj}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      label="Registro G. (RG)"
                      name="rg"
                      value={values.rg}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid> */}
                  <Grid item md={10} xs={12}>
                    <TextField
                      fullWidth
                      label="Cartão nacional de saúde (CNS)"
                      name="cns"
                      value={values.cns}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label="CEAP"
                      name="ceap"
                      value={values.ceap}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label="DDD"
                      name="ddd"
                      value={values.ddd}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Telefone fixo"
                      name="phoneone"
                      value={values.phoneone}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Telefone celular"
                      name="phonetwo"
                      value={values.phonetwo}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={ptLocale}
                    >
                      <DatePicker
                        id="date-picker-dialog"
                        label="Nascimento"
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        value={selectedDate}
                        onChange={handleDateChange}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Sexo"
                      name="male"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.male}
                      variant="outlined"
                    >
                      {sex.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Raça/Cor"
                      name="breed"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.breed}
                      variant="outlined"
                    >
                      {color.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Etinia"
                      name="ethnicity"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.ethnicity}
                      variant="outlined"
                    >
                      {ethnicity.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={2} />
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  onClick={sendData}
                  color="primary"
                  variant="contained"
                >
                  {loading ? 'Carregando...' : 'Atualizar'}
                </ButtonUpdate>
              </Box>
            </Card>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open2}
        onClose={handleClose2}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open2}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Editar informações de endereço do cliente"
                title="Endereço"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={5} xs={12}>
                    <TextField
                      fullWidth
                      label="CEP"
                      name="cep"
                      value={values.cep}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <ButtonCPF
                      onClick={checkCep}
                      color="primary"
                      variant="contained"
                    >
                      <Icon style={{ color: 'white' }}>autorenew</Icon>
                    </ButtonCPF>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      label="Numero"
                      name="number"
                      value={values.number}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label="UF"
                      name="uf"
                      value={values.uf}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label="CÓD. IBGE MUNICÍPIO"
                      name="cibge"
                      value={values.cibge}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <TextField
                      fullWidth
                      label="Rua"
                      name="street"
                      value={values.street}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <TextField
                      fullWidth
                      label="Cidade"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                {button ? (
                  <ButtonUpdate
                    onClick={updateAddress}
                    color="primary"
                    variant="contained"
                  >
                    {loading ? 'Carregando...' : 'Atualizar'}
                  </ButtonUpdate>
                ) : (
                  <ButtonUpdate
                    onClick={sendAddress}
                    color="primary"
                    variant="contained"
                  >
                    {loading ? 'Carregando...' : 'Publicar'}
                  </ButtonUpdate>
                )}
              </Box>
            </Card>
          </div>
        </Fade>
      </Modal>
      {!loadingAllPage && (
        <>
          <Box mt={5}>
            <ReactSearchAutocomplete
              items={allclients}
              onSelect={handleOnSelect}
              onSearch={handleOnSelect}
              placeholder="Buscar clientes"
              maxResults={2}
              styling={{
                borderRadius: '5px',
                height: '60px',
                hoverBackgroundColor: '#fff',
              }}
            />
          </Box>
          {!loadingData && (
            <Card className={clsx(classes.root2, className)} {...rest}>
              <PerfectScrollbar>
                <Box minWidth={1050} maxHeight={500}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>Nome vida</TableCell>
                        <TableCell>CNS</TableCell>
                        <TableCell>Telefone</TableCell>
                        <TableCell>Informações</TableCell>
                        <TableCell>Endereço</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {onlyOne ? (
                        <>
                          {clients.map((customer) => (
                            <TableRow hover key={customer && customer.id}>
                              <TableCell>
                                <Box alignItems="center" display="flex">
                                  <Avatar
                                    className={classes.avatar}
                                    src={customer && customer.avatarUrl}
                                  >
                                    {getInitials(customer && customer.name)}
                                  </Avatar>
                                  <Typography>
                                    {customer && customer.name}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell>
                                {customer && customer.namefantasy}
                              </TableCell>
                              <TableCell>{customer && customer.cns}</TableCell>
                              <TableCell>
                                {customer && customer.phoneone}
                              </TableCell>
                              <TableCell>
                                <ButtonUpdate
                                  onClick={() => handleOpen(customer)}
                                  color="alert"
                                  variant="contained"
                                >
                                  Informações
                                </ButtonUpdate>
                              </TableCell>
                              <TableCell>
                                <ButtonUpdate
                                  onClick={() => handleOpen2(customer)}
                                  color="alert"
                                  variant="contained"
                                >
                                  Endereço
                                </ButtonUpdate>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      ) : null}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
};

export default Results;
