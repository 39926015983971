/* eslint-disable no-const-assign */
import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Card,
  CardHeader,
} from '@material-ui/core';
import pt from 'date-fns/locale/pt';
import moment from 'moment';
import { subDays, addDays } from 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Page from '../../components/Page';
import Budget from './Budget';
import LatestApac from './LatestApac';
import LatestOrders from './LatestOrders';
import Sales from './Sales';
import TasksProgress from './TasksProgress';
import TotalCustomers from './TotalCustomers';
import TotalProfit from './TotalProfit';
import TrafficByDevice from './TrafficByDevice';
import TotalConfirmed from './TotalConfirmed';
import TotalRejected from './TotalRejected';
import api from '../../services/api';

const status_key_option_search = [
  {
    value: ' ',
    label: 'Todos',
  },
  {
    value: 'Fazer pedido',
    label: 'Fazer pedido',
  },
  {
    value: 'Pendente',
    label: 'Pendente',
  },
  {
    value: 'Autorizada',
    label: 'Autorizada',
  },
  {
    value: 'Confirmada',
    label: 'Confirmada',
  },
  {
    value: 'Indeferida',
    label: 'Indeferida',
  },
];
const period = [
  {
    label: 'Ontem',
    value: 1,
  },
  {
    label: '7 dias',
    value: 7,
  },
  {
    label: '15 dias',
    value: 15,
  },
  {
    label: '30 dias',
    value: 30,
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(5),
    flexDirection: `column`,
    display: `flex`,
  },
  formControl: {
    width: '100%',
  },
  formControl2: {
    width: '30%',
    alignSelf: 'center',
    marginBottom: 30,
    marginTop: 10,
  },
  header: {
    flexDirection: `row`,
    display: `flex`,
    alignItems: `center`,
    alignSelf: `center`,
    marginBottom: 20,
  },
  button: {
    border: 0,
    background: `none`,
  },
  strong: {
    color: `#000`,
    fontSize: `18px`,
    marginRight: 5,
  },
}));

export default function Dashboard() {
  const classes = useStyles();

  const [allAppointment, setAllAppointment] = useState([]);
  const [allAppointmentTotal, setAppointmentTotal] = useState([]);
  const [reload, setReload] = useState(false);

  const [allAppointmentwait, setAllAppointmentWait] = useState([]);
  const [allAppointmentservices, setAllAppointmentServices] = useState([]);
  const [allAppointmentAproved, setAllAppointmentAproved] = useState([]);
  const [allAppointmentPendent, setAllAppointmentPendent] = useState([]);
  const [allAppointmentConfirmed, setAllAppointmentConfirmed] = useState([]);
  const [allAppointmentRejected, setAllAppointmentRejected] = useState([]);
  const [apacIndice, setApacIndice] = useState(0);
  const [totalapac, setTotalApac] = useState(0);

  const [allAppointmentMakeRequest, setAllAppointmentMakeRequest] = useState(
    []
  );

  const [date, setDate] = useState(subDays(new Date(), 7));
  const [dateTwo, setDateTwo] = useState(addDays(new Date(), 7));
  const [infolista, setInfoList] = useState('Lista de espera');
  const [infoListaApac, setInfoListApac] = useState('Lista de APAC');

  const [datasfull, setDatafull] = useState();

  useEffect(() => {
    async function loadUsers() {
      const response = await api.post(`appointmentsGetWithData`, {
        date,
        dateTwo,
      });
      const datas10 = await response.data.filter((info) => {
        return (
          moment(info.createdAt).format('yyyy') === moment(date).format('yyyy')
        );
      });
      const datas12 = await datas10.filter((info) => {
        return info.obs === 'apac';
      });
      setAppointmentTotal(datas12);
      const datasfullpre = await response.data.filter((info) => {
        return (
          info.obs === 'wait' ||
          info.obs === 'screening' ||
          info.obs === 'attendance'
        );
      });
      const datafull = datasfullpre.length;
      setDatafull(datafull);
      const datasapac = await response.data.filter((info) => {
        return info.obs === 'apac';
      });
      const datas = await response.data.filter((info) => {
        const dateaa = new Date(info.createdAt);
        return dateaa >= date && dateaa <= dateTwo;
      });
      const datas2 = await datas.filter((info) => {
        return info.obs === 'apac';
      });
      const datas4 = await datas2.filter((info) => {
        return info.status_key === 'Autorizada';
      });
      const datas5 = await datas2.filter((info) => {
        return info.status_key === 'Pendente';
      });
      const datas6 = await datas2.filter((info) => {
        return info.status_key === 'Fazer pedido';
      });
      const datas7 = await datas2.filter((info) => {
        return info.status_key === 'Confirmada';
      });
      const datas8 = await datas2.filter((info) => {
        return info.status_key === 'Indeferida';
      });
      setAllAppointmentAproved(datas4);
      setAllAppointmentPendent(datas5);
      setAllAppointmentMakeRequest(datas6);
      setAllAppointmentConfirmed(datas7);
      setAllAppointmentRejected(datas8);

      const teste = `Lista de espera ${datasfullpre.length}/${datafull}`;
      const apaclist = `Lista de APAC ${apacIndice}/${datasapac.length}`;

      setInfoList(teste);
      setAllAppointmentWait(datasfullpre);
      setAllAppointment(datas2);
      setInfoListApac(apaclist);
      setTotalApac(datasapac.length);
    }
    async function loadAppointmentServices() {
      const response = await api.post(`appointmentsservicesIndexWithDate`, {
        date,
        dateTwo,
      });
      setAllAppointmentServices(response.data);
    }

    loadUsers();
    loadAppointmentServices();
  }, [date, dateTwo, reload]);

  async function handleOnSelect(item) {
    let datas = [];
    if (item === ' ') {
      const response = await api.post(`appointmentsGetWithData`, {
        date,
        dateTwo,
      });
      datas = await response.data.filter((info) => {
        return info.obs === 'apac';
      });
      setAllAppointment(datas);
    } else {
      const response = await api.post(`appointmentsGetWithData`, {
        date,
        dateTwo,
      });
      datas = await response.data.filter((info) => {
        return info.status_key === item;
      });
      const listapac = `Lista de APAC ${datas.length}/${totalapac}`;

      setApacIndice(datas.length);
      setAllAppointment(datas);
      setInfoListApac(listapac);
    }
  }
  async function handleOnPeriod(item) {
    const response = await api.post(`appointmentsGetWithData`, {
      date,
      dateTwo,
    });
    const datasapac = await response.data.filter((info) => {
      return info.obs === 'apac';
    });
    const datas = await response.data.filter((info) => {
      const dateaa = new Date(info.createdAt);
      return dateaa >= subDays(new Date(), item) && dateaa <= new Date();
    });
    const datas2 = await datas.filter((info) => {
      return info.obs === 'apac';
    });
    const datas4 = await datas2.filter((info) => {
      return info.status_key === 'Autorizada';
    });
    const datas5 = await datas2.filter((info) => {
      return info.status_key === 'Pendente';
    });
    const datas6 = await datas2.filter((info) => {
      return info.status_key === 'Fazer pedido';
    });
    const datas7 = await datas2.filter((info) => {
      return info.status_key === 'Confirmada';
    });
    const datas8 = await datas2.filter((info) => {
      return info.status_key === 'Indeferida';
    });
    setAllAppointmentAproved(datas4);
    setAllAppointmentPendent(datas5);
    setAllAppointmentMakeRequest(datas6);
    setAllAppointmentConfirmed(datas7);
    setAllAppointmentRejected(datas8);

    const datas3 = await response.data.filter((info) => {
      return (
        info.obs === 'wait' ||
        info.obs === 'screening' ||
        info.obs === 'attendance'
      );
    });

    const teste = `Lista de espera ${datas3.length}/${datasfull}`;
    const listapac = `Lista de APAC ${datas2.length}/${datasapac.length}`;
    setInfoListApac(listapac);
    setInfoList(teste);
    setAllAppointmentWait(datas3);
    setAllAppointment(datas2);
  }

  return (
    <>
      <Page className={classes.root} title="Dashboard">
        <header className={classes.header}>
          <Card>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
              <KeyboardDatePicker
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                inputVariant="filled"
                label="De"
                value={date}
                onChange={setDate}
              />
            </MuiPickersUtilsProvider>
          </Card>
          <Card style={{ marginLeft: 20 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
              <KeyboardDatePicker
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                inputVariant="filled"
                label="Até"
                value={dateTwo}
                onChange={setDateTwo}
              />
            </MuiPickersUtilsProvider>
          </Card>
        </header>
        <Card className={classes.formControl2}>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">
              Período
            </InputLabel>
            <Select
              onChange={(event) => handleOnPeriod(event.target.value)}
              name="period"
            >
              {period &&
                period.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Card>
        <Container maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item lg={4} sm={6} xl={3} xs={12}>
              <Budget makeRequest={allAppointmentMakeRequest} />
            </Grid>
            <Grid item lg={4} sm={6} xl={3} xs={12}>
              <TotalProfit pendent={allAppointmentPendent} />
            </Grid>
            <Grid item lg={4} sm={6} xl={3} xs={12}>
              <TotalCustomers aproved={allAppointmentAproved} />
            </Grid>
            <Grid item lg={4} sm={6} xl={3} xs={12}>
              <TotalConfirmed confirmed={allAppointmentConfirmed} />
            </Grid>
            <Grid item lg={4} sm={6} xl={3} xs={12}>
              <TotalRejected rejected={allAppointmentRejected} />
            </Grid>
            <Grid item lg={4} sm={6} xl={3} xs={12}>
              <TasksProgress
                makeRequest={allAppointmentMakeRequest}
                aproved={allAppointmentAproved}
                pendents={allAppointmentPendent}
                confirmed={allAppointmentConfirmed}
                rejected={allAppointmentRejected}
              />
            </Grid>
            <Grid item lg={7} md={12} xl={9} xs={12}>
              <Sales data={allAppointmentTotal} />
            </Grid>
            <Grid item lg={5} md={6} xl={3} xs={12}>
              <TrafficByDevice
                makeRequest={allAppointmentMakeRequest}
                aproved={allAppointmentAproved}
                pendents={allAppointmentPendent}
                confirmed={allAppointmentConfirmed}
                rejected={allAppointmentRejected}
              />
            </Grid>
            <Grid item lg={12} md={12} xl={9} xs={12}>
              <Card>
                <CardContent style={{ height: 90 }}>
                  <CardHeader title={infolista} />
                </CardContent>
              </Card>
              <LatestOrders
                allAppointment={allAppointmentwait}
                setReload={setReload}
                reload={reload}
              />
            </Grid>
            <Grid item lg={12} md={12} xl={9} xs={12}>
              <Card>
                <CardContent>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Status da chave
                    </InputLabel>
                    <Select
                      label="Pesquise pelo o status da chave"
                      onChange={(event) => handleOnSelect(event.target.value)}
                      name="status_key"
                    >
                      {status_key_option_search &&
                        status_key_option_search.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </CardContent>
                <Divider />
              </Card>
              <LatestApac
                setApac={infoListaApac}
                allAppointment={allAppointment}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
