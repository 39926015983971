import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { cpf, password, history } = payload;
    const response = yield call(api.post, 'sessions', {
      cpfcnpj: cpf,
      password,
    });

    const { token, user } = response.data;

    api.defaults.headers.Authorization = `Baerer ${token}`;

    yield put(signInSuccess(token, user));
    history.push('/dashboard');
  } catch (err) {
    toast.error('Falha na autenticação, verifique seu cpf ou senha');
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const {
      name,
      cpfcnpj,
      rg,
      cns,
      namefantasy,
      nmother,
      nresponsible,
      ceap,
      ddd,
      selectedDate,
      phoneone,
      phonetwo,
      male,
      breed,
      ethnicity,
      find,
      cep,
      street,
      number,
      city,
      neighborhood,
      uf,
      cibge,
    } = payload;
    const result = yield call(api.post, 'users', {
      name,
      cpfcnpj,
      rg,
      cns,
      namefantasy,
      nmother,
      nresponsible,
      ceap,
      ddd,
      born: selectedDate,
      phoneone,
      phonetwo,
      male,
      breed,
      ethnicity,
      admin: false,
    });

    toast.success('Cliente criado com sucesso!');
    if (result.status === 200) {
      if (find === true) {
        try {
          yield call(api.post, 'address', {
            id: result.data.id,
            cep,
            street,
            number,
            city,
            neighborhood,
            uf,
            cibge,
          });
        } catch (err) {
          toast.error(
            'Falha no cadastro de endereço va em cliente e modifique o endereço!'
          );
        }
      }
    }
    yield put(signFailure());
  } catch (err) {
    toast.error('Falha no cadastro verifique seus dados!');
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `Baerer ${token}`;
  }
}

export function signOut() {
  toast.success('Saiu da aplicação!');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
