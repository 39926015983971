/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIcon from '@material-ui/icons/Phone';
import TabletIcon from '@material-ui/icons/Tablet';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}));

const TrafficByDevice = ({
  className,
  makeRequest,
  pendents,
  aproved,
  confirmed,
  rejected,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const data = {
    datasets: [
      {
        data: [
          aproved.length,
          makeRequest.length,
          pendents.length,
          confirmed.length,
          rejected.length,
        ],
        backgroundColor: [
          colors.green[600],
          colors.blue[600],
          colors.orange[600],
          colors.purple[600],
          colors.red[600],
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: [
      'Autorizada',
      'Fazer pedido',
      'Pendente',
      'Confirmada',
      'Indeferida',
    ],
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
    },
  };

  const devices = [
    {
      title: 'Autorizada',
      value: isNaN(
        (aproved.length /
          (aproved.length +
            pendents.length +
            makeRequest.length +
            confirmed.length +
            rejected.length)) *
          100
      )
        ? 0
        : (
            (aproved.length /
              (aproved.length +
                pendents.length +
                makeRequest.length +
                confirmed.length +
                rejected.length)) *
            100
          ).toFixed(0),
      icon: LaptopMacIcon,
      color: colors.green[600],
    },
    {
      title: 'Fazer pedido',
      value: isNaN(
        (makeRequest.length /
          (aproved.length +
            pendents.length +
            makeRequest.length +
            confirmed.length +
            rejected.length)) *
          100
      )
        ? 0
        : (
            (makeRequest.length /
              (aproved.length +
                pendents.length +
                makeRequest.length +
                confirmed.length +
                rejected.length)) *
            100
          ).toFixed(0),
      icon: TabletIcon,
      color: colors.blue[600],
    },
    {
      title: 'Pendente',
      value: isNaN(
        (pendents.length /
          (aproved.length +
            pendents.length +
            makeRequest.length +
            confirmed.length +
            rejected.length)) *
          100
      )
        ? 0
        : (
            (pendents.length /
              (aproved.length +
                pendents.length +
                makeRequest.length +
                confirmed.length +
                rejected.length)) *
            100
          ).toFixed(0),
      icon: PhoneIcon,
      color: colors.orange[600],
    },
    {
      title: 'Confirmado',
      value: isNaN(
        (confirmed.length /
          (aproved.length +
            pendents.length +
            makeRequest.length +
            rejected.length +
            confirmed.length)) *
          100
      )
        ? 0
        : (
            (confirmed.length /
              (aproved.length +
                pendents.length +
                makeRequest.length +
                rejected.length +
                confirmed.length)) *
            100
          ).toFixed(0),
      icon: PhoneIcon,
      color: colors.purple[600],
    },
    {
      title: 'Indeferido',
      value: isNaN(
        (rejected.length /
          (aproved.length +
            pendents.length +
            makeRequest.length +
            confirmed.length +
            rejected.length)) *
          100
      )
        ? 0
        : (
            (rejected.length /
              (aproved.length +
                pendents.length +
                makeRequest.length +
                confirmed.length +
                rejected.length)) *
            100
          ).toFixed(0),
      icon: PhoneIcon,
      color: colors.red[600],
    },
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Relação entre os valores" />
      <Divider />
      <CardContent>
        <Box height={300} position="relative">
          <Doughnut data={data} options={options} />
        </Box>
        <Box display="flex" justifyContent="center" mt={10}>
          {devices.map(({ color, title, value }) => (
            <Box key={title} p={1} textAlign="center">
              <Typography color="textPrimary" variant="body2">
                {title}
              </Typography>
              <Typography style={{ color }} variant="h3">
                {value}%
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

TrafficByDevice.propTypes = {
  className: PropTypes.string,
};

export default TrafficByDevice;
