/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import Page from '../../components/Page';
import ContractCard from './ContractCard';
import ServicesCard from './ServicesCard';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  ContractCard: {
    height: '100%',
  },
}));

const ProductList = ({ location }) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Contratos">
      <Container>
        <Box mt={8}>
          <Grid container spacing={3}>
            <Grid item key={location.state.id.id} lg={12} md={12} xs={12}>
              <ContractCard
                className={classes.ContractCard}
                constracts={location.state.id}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item key={location.state.id.id} lg={12} md={12} xs={12}>
              <ServicesCard
                className={classes.ContractCard}
                location={location}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default ProductList;
