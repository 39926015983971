/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { Container, Title, Carro } from './styles';
import varizes from '../../../../assets/tratamento-de-varizes.jpg_800x532.jpg';
import catarata from '../../../../assets/cirurgia-catarata.jpg_800x533.jpg';
import glaucoma from '../../../../assets/glaucoma-tratamento.jpg_800x619.jpg';
import ultrasom from '../../../../assets/ultrassomr.jpg_800x533.jpg';
import curativo from '../../../../assets/curativo.jpg_800x515.jpg';

export default function start() {
  return (
    <Container>
      <Title>Serviços disponiveis</Title>
      <Carro
        autoPlay
        showIndicators
        infiniteLoop
        showStatus={false}
        showThumbs={false}
      >
        <div>
          <img src={varizes} width={300} />
          <p className="legend">TRATAMENTO DE VARIZES</p>
        </div>
        <div>
          <img src={catarata} width={300} />
          <p className="legend">CURURGIA DE CATARATA</p>
        </div>
        <div>
          <img src={glaucoma} width={300} />
          <p className="legend">TRATAMENTO DE GLAUCOMA</p>
        </div>
        <div>
          <img src={ultrasom} width={300} />
          <p className="legend">ULTRASSOM</p>
        </div>
        <div>
          <img src={curativo} width={300} />
          <p className="legend">CURATIVO</p>
        </div>
      </Carro>
    </Container>
  );
}
