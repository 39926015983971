/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';
import getInitials from '../../utils/getInitials';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 80,
    width: 80,
  },
  button: {
    background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
    color: 'white',
    height: 48,
    width: '100%',
    padding: '0 30px',
  },
}));

const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  const profile = useSelector((state) => state.user.profile);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar className={classes.avatar}>
            {getInitials(profile && profile.name)}
          </Avatar>
          <Typography color="textPrimary" gutterBottom variant="h3">
            {profile && profile.name}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            CPF: {`${profile && profile.cpfcnpj} `}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      {/* <CardActions>
        <Button
          className={classes.button}
          color="primary"
          fullWidth
          variant="text"
        >
          Upload picture
        </Button>
      </CardActions> */}
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
