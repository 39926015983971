/* eslint-disable no-await-in-loop */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring/web.cjs';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 800,
  },
  datePicker: {
    border: 2,
    borderColor: '#000',
    borderBottomColor: '#FFF',
    width: '100%',
    boxShadow: '0 3px 5px 2px rgba(128, 210, 201 ,.3)',
  },
}));

const TextFieldEdit = styled(TextField)({
  border: 0,
  borderColor: '#FF8E53',
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(128, 210, 201 ,.3)',
  color: 'white',
});

const ButtonUpdate = styled(Button)({
  background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 48,
  width: '100%',
  padding: '0 30px',
});

const ButtonQuit = styled(Button)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 55,
  width: 150,
});

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [services, setServices] = useState([]);
  const [cont, setCont] = useState(1);

  const [environmentVariables, setEnvironmentVariables] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadServices() {
      const response = await api.get(`allContractServices`);
      response.data.unshift({
        contracts: { name: 'Escolha' },
        services: { description: 'Escolha' },
      });
      setServices(response.data);
    }

    loadServices();
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleUpdateEnvironmentVariable(_id, field, value) {
    const environmentVariableslocal = [...environmentVariables];
    const variableToUpdate = environmentVariableslocal.find(
      (variable) => variable._id === _id
    );
    variableToUpdate[field] = value;
    setEnvironmentVariables(environmentVariableslocal);
  }

  function handleDeleteEnvironmentVariable(_id) {
    const environmentVariablesLocal = [...environmentVariables].filter(
      (variable) => variable._id !== _id
    );
    setEnvironmentVariables(environmentVariablesLocal);
  }

  function addEnvironmentVariable() {
    const environmentVariablesLocal = [...environmentVariables];
    environmentVariablesLocal.push({
      _id: cont,
      contractservice_id: '',
      quotas: '',
    });
    setCont(cont + 1);
    setEnvironmentVariables(environmentVariablesLocal);
  }

  async function sendData() {
    setLoading(true);
    const response = await api.post('doctor', {
      name: values.name,
      specialty: values.specialty,
      crm: values.crm,
      cpf: values.cpf,
    });
    if (response.status === 200) {
      toast.success('Médico criado com sucesso!');
      let response2 = [];
      for (let i = 0; i < environmentVariables.length; i += 1) {
        response2 = await api.post('doctorServices', {
          quotas: Number(environmentVariables[i].quotas),
          contractservice_id: Number(
            environmentVariables[i].contractservice_id
          ),
          doctor_id: Number(response.data.id),
        });
        if (response2.status === 200) {
          toast.success('Serviços adicionados com sucesso!');
          setLoading(false);
        }
      }
    } else {
      toast.error('Erro ao criar serviços tente novamente!');
      setLoading(false);
    }
  }
  return (
    <>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Card>
              <CardHeader
                subheader="Adicionar serviços ao médico"
                title="Serviços"
              />
              <Divider />
              {environmentVariables.map(
                ({ _id, contractservice_id, quotas }) => (
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Selecione o serviço"
                          name="contractservice_id"
                          value={contractservice_id}
                          onChange={(event) =>
                            handleUpdateEnvironmentVariable(
                              _id,
                              'contractservice_id',
                              event.target.value
                            )
                          }
                          select
                          SelectProps={{ native: true }}
                          variant="outlined"
                        >
                          {services &&
                            services.map((option) => (
                              <option
                                key={option.services.id}
                                value={option.id}
                              >
                                Contrato:{option.contracts.name} / Serviço:
                                {option.services.description}
                              </option>
                            ))}
                        </TextField>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextFieldEdit
                          fullWidth
                          label="Quantidade"
                          name="quotas"
                          value={quotas}
                          onChange={(event) =>
                            handleUpdateEnvironmentVariable(
                              _id,
                              'quotas',
                              event.target.value
                            )
                          }
                          variant="outlined"
                          type="number"
                        />
                      </Grid>
                      <Box
                        display="flex"
                        md={3}
                        xs={12}
                        justifyContent="center"
                        p={1.8}
                      >
                        <ButtonQuit
                          onClick={() => handleDeleteEnvironmentVariable(_id)}
                          color="alert"
                          variant="contained"
                        >
                          X
                        </ButtonQuit>
                      </Box>
                    </Grid>
                  </CardContent>
                )
              )}
              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  color="primary"
                  variant="contained"
                  onClick={addEnvironmentVariable}
                >
                  Adicionar serviço
                </ButtonUpdate>
              </Box>
              <Divider />

              <Box display="flex" justifyContent="flex-end" p={2}>
                <ButtonUpdate
                  color="primary"
                  variant="contained"
                  onClick={sendData}
                >
                  {loading ? 'Carregando...' : 'Criar médico'}
                </ButtonUpdate>
              </Box>
            </Card>
          </div>
        </Fade>
      </Modal>
      <form
        autoComplete="off"
        noValidate
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Card>
          <CardHeader
            subheader="Informações para cadastro do médico"
            title="Médico"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextFieldEdit
                  fullWidth
                  label="Nome do médico"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextFieldEdit
                  fullWidth
                  label="Especialidade do médico"
                  name="specialty"
                  onChange={handleChange}
                  value={values.specialty}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextFieldEdit
                  fullWidth
                  label="CRM"
                  name="crm"
                  onChange={handleChange}
                  value={values.crm}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextFieldEdit
                  fullWidth
                  label="CPF"
                  name="cpf"
                  onChange={handleChange}
                  value={values.cpf}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <ButtonUpdate
              color="primary"
              variant="contained"
              onClick={() => handleOpen()}
            >
              Adicionar Serviços
            </ButtonUpdate>
          </Box>
        </Card>
      </form>
    </>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
