/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { parseISO, differenceInDays } from 'date-fns';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex',
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ContractCard = ({ className, constracts, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {constracts.code}
        </Typography>
        <Typography align="center" color="textPrimary" variant="body1">
          Nome do contrato: {constracts.name}
        </Typography>
        <Typography align="center" color="textPrimary" variant="body1">
          Data do contrato:{' '}
          {moment(constracts.date_contract).format('DD/MM/YYYY')}
        </Typography>
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      <Box p={2}>
        <Grid container justify="space-between" spacing={2}>
          <Grid className={classes.statsItem} item>
            <AccessTimeIcon className={classes.statsIcon} color="action" />
            <Typography color="textSecondary" display="inline" variant="body2">
              Alterado à{' '}
              {differenceInDays(parseISO(constracts.date_contract), new Date())}{' '}
              dias
            </Typography>
          </Grid>
          <Grid className={classes.statsItem} item>
            <Typography color="textSecondary" display="inline" variant="body2">
              {constracts.quotas} Serviços
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

ContractCard.propTypes = {
  className: PropTypes.string,
  constracts: PropTypes.object.isRequired,
};

export default ContractCard;
