export const MenuItems = [
  {
    title: 'Consultar',
    path: '/showContracts',
    cName: 'dropdown-link',
  },
  {
    title: 'Adicionar',
    path: '/createContracts',
    cName: 'dropdown-link',
  },
];
