import React, { useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from '../../components/Page';
import ContactAppointment from './ContactAppointment';
import ScreeningAppointment from './ScreeningAppointment';
import AttendanceAppointments from './AttendanceAppointments';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Account = () => {
  const classes = useStyles();
  const [update, setUpdate] = useState(false);

  return (
    <Page className={classes.root} title="Criar Agendamento">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <ContactAppointment update={update} setUpdate={setUpdate} />
            <ScreeningAppointment update={update} setUpdate={setUpdate} />
            <AttendanceAppointments update={update} setUpdate={setUpdate} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
