/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Icon,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ptLocale from 'date-fns/locale/pt';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ceps from 'cep-promise';
import { toast } from 'react-toastify';
import { signUpRequest } from '../../store/modules/auth/actions';

const useStyles = makeStyles(() => ({
  root: {},
}));

const TextFieldEdit = styled(TextField)({
  border: 0,
  borderColor: '#FF8E53',
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(128, 210, 201 ,.3)',
  color: 'white',
});

const ButtonUpdate = styled(Button)({
  background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 48,
  width: '100%',
  padding: '0 30px',
});
const ButtonCPF = styled(Button)({
  background: 'linear-gradient(45deg, #0eb8a9 30%, #80D2C9 90%)',
  border: 0,
  borderRadius: 3,
  marginTop: 1,
  width: '100%',
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 52,
});

const sex = [
  {
    value: 'Selecione',
    label: 'Selecione',
  },
  {
    value: false,
    label: 'Feminino',
  },
  {
    value: true,
    label: 'Masculino',
  },
];

const color = [
  {
    value: 'Selecione',
    label: 'Selecione',
  },
  {
    value: 'Preta',
    label: 'Preta',
  },
  {
    value: 'Branca',
    label: 'Branca',
  },
  {
    value: 'Parda',
    label: 'Parda',
  },
  {
    value: 'Amarela',
    label: 'Amarela',
  },
  {
    value: 'Indígena',
    label: 'Indígena',
  },
];
const ethnicity = [
  {
    value: 'Selecione',
    label: 'Selecione',
  },
  {
    value: 'Mulatos',
    label: 'Mulatos',
  },
  {
    value: 'Caboclos',
    label: 'Caboclos',
  },
  {
    value: 'Cafuzo',
    label: 'Cafuzo',
  },
  {
    value: 'Indígenas',
    label: 'Indígenas',
  },
  {
    value: 'Brancos',
    label: 'Brancos',
  },
  {
    value: 'Negros',
    label: 'Negros',
  },
];
const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({ uf: ' ', city: ' ', street: ' ' });
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [find, setFind] = useState(false);

  function handleSubmit() {
    dispatch(
      signUpRequest(
        values.name,
        values.cpfcnpj,
        values.rg,
        values.cns,
        values.namefantasy,
        values.nmother,
        values.nresponsible,
        values.ceap,
        values.ddd,
        selectedDate,
        values.phoneone,
        values.phonetwo,
        values.male,
        values.breed,
        values.ethnicity,
        find,
        values.cep,
        values.street,
        values.number,
        values.city,
        values.neighborhood,
        values.uf,
        values.cibge
      )
    );
  }

  async function checkCep() {
    await ceps(values.cep)
      .then(async (res) => {
        setFind(true);
        await setValues({
          ...values,
          city: res.city,
          neighborhood: res.neighborhood,
          uf: res.state,
          street: res.street,
        });
      })
      .catch(() => {
        setFind(false);
        toast.error('Cep invalido!');
      });
  }

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="Dados para criar cliente" title="Cliente" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Nome"
                name="name"
                value={values.name}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Nome Vida"
                name="namefantasy"
                value={values.namefantasy}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Nome da mãe"
                name="nmother"
                value={values.nmother}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Nome do responsável"
                name="nresponsible"
                value={values.nresponsible}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                fullWidth
                label="Cartão nacional de saúde (CNS)"
                name="cns"
                value={values.cns}
                onChange={handleChange}
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                fullWidth
                label="CEAP"
                name="ceap"
                value={values.ceap}
                onChange={handleChange}
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                fullWidth
                label="DDD"
                name="ddd"
                value={values.ddd}
                onChange={handleChange}
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Telefone fixo"
                name="phoneone"
                value={values.phoneone}
                onChange={handleChange}
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Telefone celular"
                name="phonetwo"
                value={values.phonetwo}
                onChange={handleChange}
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                <DatePicker
                  id="date-picker-dialog"
                  label="Nascimento"
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Sexo"
                name="male"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={values.male}
                variant="outlined"
              >
                {sex.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Raça/Cor"
                name="breed"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={values.breed}
                variant="outlined"
              >
                {color.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Etinia"
                name="ethnicity"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={values.ethnicity}
                variant="outlined"
              >
                {ethnicity.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <div className={classes.column}>
              <Typography className={classes.heading}>
                Adicionar endereço
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                  <TextFieldEdit
                    fullWidth
                    label="CEP"
                    name="cep"
                    value={values.cep}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <ButtonCPF
                    color="primary"
                    onClick={checkCep}
                    variant="contained"
                  >
                    <Icon style={{ color: 'white' }}>autorenew</Icon>
                  </ButtonCPF>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextFieldEdit
                    fullWidth
                    label="Rua"
                    name="street"
                    value={values.street}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextFieldEdit
                    fullWidth
                    label="Numero"
                    name="number"
                    value={values.number}
                    onChange={handleChange}
                    variant="outlined"
                    type="number"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextFieldEdit
                    fullWidth
                    label="CÓD. IBGE MUNICÍPIO"
                    name="cibge"
                    value={values.cibge}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={7} xs={12}>
                  <TextFieldEdit
                    fullWidth
                    label="Cidade"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextFieldEdit
                    fullWidth
                    label="UF"
                    name="uf"
                    value={values.uf}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </AccordionDetails>
        </Accordion>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <ButtonUpdate
            onClick={handleSubmit}
            color="primary"
            variant="contained"
          >
            {loading ? 'Carregando...' : 'Criar cliente'}
          </ButtonUpdate>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
