import styled from 'styled-components';
import logoCLimed from '../../assets/logo-climed-brasilbranco.png_940x629.png';

export const Container = styled.div`
  background-repeat: no-repeat;
  background-size: auto;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
`;

export const ContainerNav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
`;

export const Para = styled.div`
  @media screen and (max-width: 768px) {
    height: 350px;
  }
  height: 600px;
`;

export const Logo = styled.div`
  @media screen and (max-width: 768px) {
    left: 0px;
  }
  width: 50px;
  height: 50px;
  left: 100px;
  top: 5px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: auto;
  background-image: url(${logoCLimed});
`;

export const ToolBarNavigation = styled.div`
  @media screen and (max-width: 360px) {
    padding: 0 0rem;
  }
  @media screen and (max-width: 768px) {
    padding: 0 0.5rem;
  }
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 11rem;
  background: #006059;
`;

export const ContainerContact = styled.div`
  background: #6dac3c;
  padding-top: 60;
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const ContainerLinks = styled.div`
  a {
    color: #fff;
    text-decoration: none;
  }
  a:hover {
    color: #57c6ba;
  }
  a:active {
    color: #57c6ba;
  }
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    font-size: 1rem;
    font-weight: 400;
  }
  li {
    @media screen and (max-width: 768px) {
      padding: 0 8px;
    }
    padding: 0 1.4rem;
  }
`;
