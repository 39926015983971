/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useHistory } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import api from '../../services/api';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end',
  },
  statuspedding: {
    background: `#ff9800`,
    color: `#FFF`,
  },
  statusaproved: {
    background: `#76ff03`,
    color: `#FFF`,
  },
  statusmakerequest: {
    background: `#ab003c`,
    color: `#FFF`,
  },
}));

const ButtonUpdate = styled(Button)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  marginTop: 5,
  marginLeft: -30,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
});

const LatestOrders = ({
  className,
  allAppointment,
  setReload,
  reload,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();
  async function removeAppointment(data) {
    const response = await api.post('appointmentsRemove', {
      id: data.id,
    });
    if (response.status === 200) {
      toast.success('Serviço removido com sucesso');
      setReload(!reload);
    } else {
      toast.error('Erro ao remover serviço tente novamente!');
      setReload(!reload);
    }
  }
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Divider />

      <PerfectScrollbar style={{ height: 400 }}>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id do atendimento</TableCell>
                <TableCell>Paciente</TableCell>
                <TableCell>Atendente</TableCell>
                <TableCell>Serviço</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Remover</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allAppointment.map((order, index) => (
                <TableRow hover key={order.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{order.user.name}</TableCell>
                  <TableCell>{order.created.name}</TableCell>
                  {order.obs === 'attendance' && (
                    <TableCell>Atendimento</TableCell>
                  )}
                  {order.obs === 'wait' && (
                    <TableCell>Lista de espera</TableCell>
                  )}
                  {order.obs === 'screening' && <TableCell>Triagem</TableCell>}
                  <TableCell>
                    {order.date && moment(order.date).format('DD/MM/YYYY')}
                  </TableCell>

                  <TableCell>
                    <ButtonUpdate
                      onClick={() => removeAppointment(order)}
                      color="alert"
                      variant="contained"
                    >
                      Remover
                    </ButtonUpdate>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
          onClick={() => history.push('/AddAppointment')}
        >
          Ver Todas
        </Button>
      </Box>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string,
  allAppointment: PropTypes.array,
  reload: PropTypes.bool,
};

export default LatestOrders;
