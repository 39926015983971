/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { getMonth, parseISO } from 'date-fns';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
}));

function Sales({ className, data, ...rest }) {
  const classes = useStyles();
  const theme = useTheme();

  const jan = data.filter((info) => {
    return getMonth(new Date(info.date)) === 0;
  });
  const autorizadajan = jan.filter((info) => {
    return info.status_key === 'Autorizada';
  });
  const pendentejan = jan.filter((info) => {
    return info.status_key === 'Pendente';
  });
  const makejan = jan.filter((info) => {
    return info.status_key === 'Fazer pedido';
  });
  const confjan = jan.filter((info) => {
    return info.status_key === 'Confirmada';
  });
  const indjan = jan.filter((info) => {
    return info.status_key === 'Indeferida';
  });

  const fev = data.filter((info) => {
    return getMonth(new Date(info.date)) === 1;
  });
  const autorizadafev = fev.filter((info) => {
    return info.status_key === 'Autorizada';
  });
  const pendentefev = fev.filter((info) => {
    return info.status_key === 'Pendente';
  });
  const makefev = fev.filter((info) => {
    return info.status_key === 'Fazer pedido';
  });
  const conffev = fev.filter((info) => {
    return info.status_key === 'Confirmada';
  });
  const indfev = fev.filter((info) => {
    return info.status_key === 'Indeferida';
  });

  const mar = data.filter((info) => {
    return getMonth(new Date(info.date)) === 2;
  });
  const autorizadamar = mar.filter((info) => {
    return info.status_key === 'Autorizada';
  });
  const pendentemar = mar.filter((info) => {
    return info.status_key === 'Pendente';
  });
  const makemar = mar.filter((info) => {
    return info.status_key === 'Fazer pedido';
  });
  const confmar = mar.filter((info) => {
    return info.status_key === 'Confirmada';
  });
  const indmar = mar.filter((info) => {
    return info.status_key === 'Indeferida';
  });

  const abril = data.filter((info) => {
    return getMonth(new Date(info.date)) === 3;
  });
  const autorizadaabril = abril.filter((info) => {
    return info.status_key === 'Autorizada';
  });
  const pendenteabril = abril.filter((info) => {
    return info.status_key === 'Pendente';
  });
  const makeabril = abril.filter((info) => {
    return info.status_key === 'Fazer pedido';
  });
  const confabril = abril.filter((info) => {
    return info.status_key === 'Confirmada';
  });
  const indabril = abril.filter((info) => {
    return info.status_key === 'Indeferida';
  });

  const maio = data.filter((info) => {
    return getMonth(new Date(info.date)) === 4;
  });
  const autorizadamaio = maio.filter((info) => {
    return info.status_key === 'Autorizada';
  });
  const pendentemaio = maio.filter((info) => {
    return info.status_key === 'Pendente';
  });
  const makemaio = maio.filter((info) => {
    return info.status_key === 'Fazer pedido';
  });
  const confmaio = maio.filter((info) => {
    return info.status_key === 'Confirmada';
  });
  const indmaio = maio.filter((info) => {
    return info.status_key === 'Indeferida';
  });

  const junho = data.filter((info) => {
    return getMonth(new Date(info.date)) === 5;
  });
  const autorizadajun = junho.filter((info) => {
    return info.status_key === 'Autorizada';
  });
  const pendentejun = junho.filter((info) => {
    return info.status_key === 'Pendente';
  });
  const makejun = junho.filter((info) => {
    return info.status_key === 'Fazer pedido';
  });
  const confjun = junho.filter((info) => {
    return info.status_key === 'Confirmada';
  });
  const indjun = junho.filter((info) => {
    return info.status_key === 'Indeferida';
  });

  const julho = data.filter((info) => {
    return getMonth(new Date(info.date)) === 6;
  });
  const autorizadajulho = julho.filter((info) => {
    return info.status_key === 'Autorizada';
  });
  const pendentejulho = julho.filter((info) => {
    return info.status_key === 'Pendente';
  });
  const makejulho = julho.filter((info) => {
    return info.status_key === 'Fazer pedido';
  });
  const confjulho = julho.filter((info) => {
    return info.status_key === 'Confirmada';
  });
  const indjulho = julho.filter((info) => {
    return info.status_key === 'Indeferida';
  });

  const agosto = data.filter((info) => {
    return getMonth(new Date(info.date)) === 7;
  });
  const autorizadaagosto = agosto.filter((info) => {
    return info.status_key === 'Autorizada';
  });
  const pendenteagosto = agosto.filter((info) => {
    return info.status_key === 'Pendente';
  });
  const makeagosto = agosto.filter((info) => {
    return info.status_key === 'Fazer pedido';
  });
  const confagosto = agosto.filter((info) => {
    return info.status_key === 'Confirmada';
  });
  const indagosto = agosto.filter((info) => {
    return info.status_key === 'Indeferida';
  });

  const setembro = data.filter((info) => {
    return getMonth(new Date(info.date)) === 8;
  });
  const autorizadasetembro = setembro.filter((info) => {
    return info.status_key === 'Autorizada';
  });
  const pendentesetembro = setembro.filter((info) => {
    return info.status_key === 'Pendente';
  });
  const makesetembro = setembro.filter((info) => {
    return info.status_key === 'Fazer pedido';
  });
  const confsetembro = setembro.filter((info) => {
    return info.status_key === 'Confirmada';
  });
  const indsetembro = setembro.filter((info) => {
    return info.status_key === 'Indeferida';
  });

  const outubro = data.filter((info) => {
    return getMonth(new Date(info.date)) === 9;
  });
  const autorizadaoutubro = outubro.filter((info) => {
    return info.status_key === 'Autorizada';
  });
  const pendenteoutubro = outubro.filter((info) => {
    return info.status_key === 'Pendente';
  });
  const makeoutubro = outubro.filter((info) => {
    return info.status_key === 'Fazer pedido';
  });
  const confoutubro = outubro.filter((info) => {
    return info.status_key === 'Confirmada';
  });
  const indoutubro = outubro.filter((info) => {
    return info.status_key === 'Indeferida';
  });

  const novembro = data.filter((info) => {
    return getMonth(new Date(info.date)) === 10;
  });
  const autorizadanovembro = novembro.filter((info) => {
    return info.status_key === 'Autorizada';
  });
  const pendentenovembro = novembro.filter((info) => {
    return info.status_key === 'Pendente';
  });
  const makenovembro = novembro.filter((info) => {
    return info.status_key === 'Fazer pedido';
  });
  const confnovembro = novembro.filter((info) => {
    return info.status_key === 'Confirmada';
  });
  const indnovembro = novembro.filter((info) => {
    return info.status_key === 'Indeferida';
  });

  const dezembro = data.filter((info) => {
    return getMonth(new Date(info.date)) === 11;
  });
  const autorizadadezembro = dezembro.filter((info) => {
    return info.status_key === 'Autorizada';
  });
  const pendentedezembro = dezembro.filter((info) => {
    return info.status_key === 'Pendente';
  });
  const makedezembro = dezembro.filter((info) => {
    return info.status_key === 'Fazer pedido';
  });
  const confdezembro = dezembro.filter((info) => {
    return info.status_key === 'Confirmada';
  });
  const inddezembro = dezembro.filter((info) => {
    return info.status_key === 'Indeferida';
  });

  const datas = {
    datasets: [
      {
        backgroundColor: colors.green[600],
        data: [
          autorizadajan.length,
          autorizadafev.length,
          autorizadamar.length,
          autorizadaabril.length,
          autorizadamaio.length,
          autorizadajun.length,
          autorizadajulho.length,
          autorizadaagosto.length,
          autorizadasetembro.length,
          autorizadaoutubro.length,
          autorizadanovembro.length,
          autorizadadezembro.length,
        ],
        label: 'Autorizada',
      },
      {
        backgroundColor: colors.orange[600],
        data: [
          pendentejan.length,
          pendentefev.length,
          pendentemar.length,
          pendenteabril.length,
          pendentemaio.length,
          pendentejun.length,
          pendentejulho.length,
          pendenteagosto.length,
          pendentesetembro.length,
          pendenteoutubro.length,
          pendentenovembro.length,
          pendentedezembro.length,
        ],
        label: 'Pendente',
      },
      {
        backgroundColor: colors.blue[600],
        data: [
          makejan.length,
          makefev.length,
          makemar.length,
          makeabril.length,
          makemaio.length,
          makejun.length,
          makejulho.length,
          makeagosto.length,
          makesetembro.length,
          makeoutubro.length,
          makenovembro.length,
          makedezembro.length,
        ],
        label: 'Fazer Pedido',
      },
      {
        backgroundColor: colors.purple[600],
        data: [
          confjan.length,
          conffev.length,
          confmar.length,
          confabril.length,
          confmaio.length,
          confjun.length,
          confjulho.length,
          confagosto.length,
          confsetembro.length,
          confoutubro.length,
          confnovembro.length,
          confdezembro.length,
        ],
        label: 'Confirmada',
      },
      {
        backgroundColor: colors.red[600],
        data: [
          indjan.length,
          indfev.length,
          indmar.length,
          indabril.length,
          indmaio.length,
          indjun.length,
          indjulho.length,
          indagosto.length,
          indsetembro.length,
          indoutubro.length,
          indnovembro.length,
          inddezembro.length,
        ],
        label: 'Indeferida',
      },
    ],
    labels: [
      'Jan',
      'Fev',
      'Mar',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agos',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Relatorio do mês" />
      <Divider />
      <CardContent>
        <Box height={500} position="relative">
          <Bar data={datas} options={options} />
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
}

Sales.propTypes = {
  className: PropTypes.string,
};

export default Sales;
