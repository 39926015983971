/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../../assets/logo.png';
import { Container, Content, Profile, Badge } from './styles';
import DropdownAppointment from './DropdownAppointment';
import DropdownClient from './DropdownClient';
import DropdownContract from './DropdownContract';
import DropdownUserAppointments from './DropdownUserAppointments';
import DropdownDoctors from './DropdownDoctors';
import DropdownApac from './DropdownApac';
import getInitials from '../../utils/getInitials';

export default function Header() {
  const profile = useSelector((state) => state.user.profile);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown3, setDropdown3] = useState(false);
  const [dropdown4, setDropdown4] = useState(false);
  const [dropdown5, setDropdown5] = useState(false);
  const [dropdown6, setDropdown6] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const onMouseEnter2 = () => {
    if (window.innerWidth < 960) {
      setDropdown2(false);
    } else {
      setDropdown2(true);
    }
  };

  const onMouseLeave2 = () => {
    if (window.innerWidth < 960) {
      setDropdown2(false);
    } else {
      setDropdown2(false);
    }
  };

  const onMouseEnter3 = () => {
    if (window.innerWidth < 960) {
      setDropdown3(false);
    } else {
      setDropdown3(true);
    }
  };

  const onMouseLeave3 = () => {
    if (window.innerWidth < 960) {
      setDropdown3(false);
    } else {
      setDropdown3(false);
    }
  };

  const onMouseEnter4 = () => {
    if (window.innerWidth < 960) {
      setDropdown4(true);
    } else {
      setDropdown4(true);
    }
  };

  const onMouseLeave4 = () => {
    if (window.innerWidth < 960) {
      setDropdown4(false);
    } else {
      setDropdown4(false);
    }
  };
  const onMouseEnter5 = () => {
    if (window.innerWidth < 960) {
      setDropdown5(true);
    } else {
      setDropdown5(true);
    }
  };

  const onMouseLeave5 = () => {
    if (window.innerWidth < 960) {
      setDropdown5(false);
    } else {
      setDropdown5(false);
    }
  };
  const onMouseEnter6 = () => {
    if (window.innerWidth < 960) {
      setDropdown6(true);
    } else {
      setDropdown6(true);
    }
  };

  const onMouseLeave6 = () => {
    if (window.innerWidth < 960) {
      setDropdown6(false);
    } else {
      setDropdown6(false);
    }
  };

  // function SideBarClickHandler() {
  //   setSideBarOpen(!sideBarOpen);
  // }

  return (
    <Container>
      <Content>
        <nav>
          <img src={logo} height="60" width="80" alt="Climed" />

          <div className="nav-item">
            <Link to="/dashboard" className="nav-links">
              DASHBOARD <i className="fas fa-caret-down" />
            </Link>
          </div>
          <div
            className="nav-item"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Link className="nav-links">
              AGENDA <i className="fas fa-caret-down" />
            </Link>
            {dropdown && <DropdownAppointment />}
          </div>
          <div
            className="nav-item"
            onMouseEnter={onMouseEnter6}
            onMouseLeave={onMouseLeave6}
          >
            <Link className="nav-links">
              APAC <i className="fas fa-caret-down" />
            </Link>
            {dropdown6 && <DropdownApac />}
          </div>
          <div className="nav-item">
            <Link to="/createJustification" className="nav-links">
              JUSTIFICATIVA <i className="fas fa-caret-down" />
            </Link>
          </div>

          {profile && profile.admin ? (
            <div
              className="nav-item"
              onMouseEnter={onMouseEnter4}
              onMouseLeave={onMouseLeave4}
            >
              <Link className="nav-links">
                CONTRATO <i className="fas fa-caret-down" />
              </Link>
              {dropdown4 && <DropdownContract />}
            </div>
          ) : null}

          {profile && profile.admin ? (
            <div
              className="nav-item"
              onMouseEnter={onMouseEnter2}
              onMouseLeave={onMouseLeave2}
            >
              <Link className="nav-links">
                USUÁRIOS <i className="fas fa-caret-down" />
              </Link>
              {dropdown2 && <DropdownUserAppointments />}
            </div>
          ) : null}

          {profile && profile.admin ? (
            <div className="nav-item">
              <Link to="/consultServices" className="nav-links">
                SERVIÇO <i className="fas fa-caret-down" />
              </Link>
            </div>
          ) : null}

          {profile && profile.admin ? (
            <div
              className="nav-item"
              onMouseEnter={onMouseEnter5}
              onMouseLeave={onMouseLeave5}
            >
              <Link className="nav-links">
                MÉDICOS <i className="fas fa-caret-down" />
              </Link>
              {dropdown5 && <DropdownDoctors />}
            </div>
          ) : null}

          <div
            className="nav-item"
            onMouseEnter={onMouseEnter3}
            onMouseLeave={onMouseLeave3}
          >
            <Link className="nav-links">
              CLIENTE <i className="fas fa-caret-down" />
            </Link>
            {dropdown3 && <DropdownClient />}
          </div>
        </nav>

        <aside>
          <Profile>
            <div>
              <strong style={{ marginTop: 5 }}>
                {profile && profile.name}
              </strong>
              <Link to="/profile">Meu perfil</Link>
            </div>
            <Avatar>{getInitials(profile && profile.name)}</Avatar>
          </Profile>
          {/* <Badge onClick={SideBarClickHandler}>
            <MenuIcon color="#FFF" size={25} />
          </Badge> */}
        </aside>
      </Content>
    </Container>

    // {sideBarOpen === true ? (
    //   <>
    //     {/* <SideBar click={SideBarClickHandler} />
    //     <BackDrop click={SideBarClickHandler} /> */}
    //   </>
    // ) : null}
  );
}
