/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import Page from '../../components/Page';
import ServiceList from './ServiceList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  ContractCard: {
    height: '100%',
  },
}));

const ProductList = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Justificativa">
      <Container>
        <Box mt={1}>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <ServiceList className={classes.ContractCard} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default ProductList;
