export const MenuItems = [
  {
    title: 'Consultar',
    path: '/ConsultAppointment',
    cName: 'dropdown-link',
  },
  {
    title: 'Agendar',
    path: '/AddAppointment',
    cName: 'dropdown-link',
  },
];
