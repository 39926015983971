import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import Page from '../../components/Page';
import ContractCard from './ContractCard';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  ContractCard: {
    height: '100%',
  },
}));

const ProductList = () => {
  const classes = useStyles();
  const history = useHistory();

  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const [constracts, setContracts] = useState([]);

  useEffect(() => {
    async function loadContracts() {
      const response = await api.get(`allContracts?page=${page}`);

      setContracts(response.data);
      if (response.data.length === 6 && page >= count) {
        setCount(count + 1);
      }
    }

    loadContracts();
  }, [page]);

  return (
    <Page className={classes.root} title="Contratos">
      <Container maxWidth={false}>
        <Box mt={3}>
          <Grid container spacing={3}>
            {constracts.map((contract) => (
              <Grid
                onClick={() =>
                  history.push({
                    pathname: '/editContract',
                    state: { id: contract },
                  })
                }
                item
                key={contract.id}
                lg={4}
                md={6}
                xs={12}
              >
                <ContractCard
                  className={classes.ContractCard}
                  constracts={contract}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box mt={3} display="flex" justifyContent="center">
          <Pagination page={page} count={count} onChange={handleChange} />
        </Box>
      </Container>
    </Page>
  );
};

export default ProductList;
