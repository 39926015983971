/* eslint-disable import/extensions */
import React, { useState, useEffect, useRef } from 'react';
import Scrollchor from 'react-scrollchor';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-parallax';
import image2 from '../../assets/atendimento-medico.jpg_2000x1125.jpg';

import {
  Container,
  ContainerNav,
  Para,
  ContainerLinks,
  ToolBarNavigation,
} from './style.js';

import Start from './Pages/Start';
import Second from './Pages/Second';
import Third from './Pages/Third';

export default function App() {
  const [navBackground, setNavBackground] = useState('transparent');
  const navRef = useRef();
  navRef.current = navBackground;

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY >= 100;

      if (show) {
        setNavBackground('#006059');
      } else {
        setNavBackground('transparent');
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Container>
      <ContainerNav
        style={{
          transition: '1s ease',
          backgroundColor: navBackground,
          zIndex: 10,
        }}
      >
        <ToolBarNavigation>
          <ContainerLinks>
            <ul>
              <li>
                <Scrollchor to="#start">Inicio</Scrollchor>
              </li>
              <li>
                <Scrollchor to="#services">Serviços</Scrollchor>
              </li>
              <li>
                <Scrollchor to="#contact">Contato</Scrollchor>
              </li>
              <li>
                <Link
                  style={{
                    borderRadius: 5,
                    backgroundColor: '#23AA9D',
                    padding: 8,
                  }}
                  to="/login"
                >
                  Sistema
                </Link>
              </li>
            </ul>
          </ContainerLinks>
        </ToolBarNavigation>
      </ContainerNav>
      <Container>
        <div id="start" style={{ paddingTop: 10 }}>
          <Start />
        </div>
        <Parallax bgImage={image2} strength={500}>
          <Para />
        </Parallax>
        <div id="services">
          <Second />
        </div>
        <div id="contact">
          <Third />
        </div>
      </Container>
    </Container>
  );
}
