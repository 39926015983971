import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding: 0 30px;
`;

export const Content = styled.div`
  height: 64px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  nav {
    display: flex;
    align-items: center;
    .nav-links:hover {
      background-color: #0eb8a9;
      border-radius: 4px;
      color: #fff;
      transition: all 0.2s ease-out;
    }

    img {
      margin-right: 20px;
      padding-right: 20px;
      border-right: 1px solid #eee;
    }

    a {
      color: #0eb8a9;
      text-decoration: none;
      padding: 0.5rem 1rem;
      font: 14px Arial, sans-serif;
      font-weight: bold;
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
  aside {
    display: flex;
    align-items: center;
  }
`;

export const Profile = styled.div`
  display: flex;
  height: 40px;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #eee;
  div {
    text-align: right;
    margin-right: 10px;
    strong {
      display: block;
      color: #0eb8a9;
      font: 15px Arial, sans-serif;
      font-weight: bold;
    }
    a {
      display: block;
      margin-top: 2px;
      font-size: 12px;
      color: #0eb8a9;
    }
  }
`;

export const Badge = styled.button`
  display: none;
  @media screen and (max-width: 1024px) {
    background: none;
    border: 0;
    display: flex;
    position: relative;
    ${(props) =>
      props.hasUnread &&
      css`
        &::after {
          position: absolute;
          right: 0;
          top: 0;
          width: 8px;
          height: 8px;
          background: #ff892e;
          content: '';
          border-radius: 50%;
        }
      `}
  }
`;
