import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';

export const Container = styled.div`
  background-color: #ddf6f4;
  display: flex;
  background-size: auto;
  height: 800px;
  background-repeat: no-repeat;
  background-size: auto;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1024px) {
    height: 350px;
  }
`;

export const Carro = styled(Carousel)`
  @media screen and (max-width: 1024px) {
    width: 350px;
  }
  width: 1000px;
`;

export const Title = styled.h2`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.2;
  margin-top: 30px;

  margin-bottom: 1.25rem;
  color: #2d2d2d;
`;

export const ContainerCarrousel = styled.div`
  max-width: 950px;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
