/* eslint-disable no-restricted-globals */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  makeStyles,
  colors,
} from '@material-ui/core';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  avatar: {
    backgroundColor: colors.orange[600],
    height: 56,
    width: 56,
  },
}));

const TasksProgress = ({
  className,
  makeRequest,
  pendents,
  aproved,
  confirmed,
  rejected,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              PROGRESSO DO MÊS
            </Typography>
            <Typography color="textPrimary" variant="h4">
              {isNaN(
                (aproved.length /
                  (aproved.length +
                    pendents.length +
                    makeRequest.length +
                    confirmed.length +
                    rejected.length)) *
                  100
              )
                ? 0
                : (
                    (aproved.length /
                      (aproved.length +
                        pendents.length +
                        makeRequest.length +
                        confirmed.length +
                        rejected.length)) *
                    100
                  ).toFixed(2)}
              %
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <InsertChartIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={0.5}>
          <LinearProgress
            value={
              (aproved.length /
                (aproved.length +
                  pendents.length +
                  makeRequest.length +
                  confirmed.length +
                  rejected.length)) *
                100 !==
              NaN
                ? (aproved.length /
                    (aproved.length +
                      pendents.length +
                      makeRequest.length +
                      confirmed.length +
                      rejected.length)) *
                  100
                : 0
            }
            variant="determinate"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

TasksProgress.propTypes = {
  className: PropTypes.string,
};

export default TasksProgress;
