/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Box, Card, CardContent, Grid, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';

import ptLocale from 'date-fns/locale/pt';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ceps from 'cep-promise';
import { toast } from 'react-toastify';
import { Form, Datea } from './styles';
import { signUpRequest } from '../../../../store/modules/LandingPage/actions';

const useStyles = makeStyles(() => ({
  root: { marginRight: 140, marginLeft: 140 },
}));

const TextFieldEdit = styled(TextField)({
  border: 0,
  borderColor: '#FF8E53',
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(128, 210, 201 ,.3)',
  color: 'white',
});

const ButtonUpdate = styled(Button)({
  background: '#015D58',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 48,
  width: '100%',
  padding: '0 30px',
});
const ButtonCPF = styled(Button)({
  background: '#015D58',
  border: 0,
  borderRadius: 3,
  marginTop: 1,
  width: '100%',
  boxShadow: '0 3px 5px 2px rgba(72, 198, 186,.3)',
  color: 'white',
  height: 52,
});

const sex = [
  {
    value: '',
    label: '',
  },
  {
    value: false,
    label: 'Feminino',
  },
  {
    value: true,
    label: 'Masculino',
  },
];
const service = [
  {
    value: '',
    label: '',
  },
  {
    value: 3,
    label: 'Ultrassonografia Doppler Colorido de Vasos',
  },
  {
    value: 4,
    label: 'Consulta de Profissional de Nível Superior (Exceto Médico)',
  },
  {
    value: 5,
    label: 'Consulta Médica em Atenção Especializada',
  },
  {
    value: 6,
    label: 'Tratamento Esclerozante não Estético de Varizes (Unilateral)',
  },
  {
    value: 7,
    label: 'Tratamento Esclerozante não Estético de Varizes (Bilateral)',
  },
  {
    value: 8,
    label: 'Curativo Grau II C/ ou S Debridamento',
  },
  {
    value: 9,
    label: 'Debridamento de Ulcera/ Necrose',
  },
];

const color = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Preta',
    label: 'Preta',
  },
  {
    value: 'Branca',
    label: 'Branca',
  },
  {
    value: 'Parda',
    label: 'Parda',
  },
  {
    value: 'Amarela',
    label: 'Amarela',
  },
  {
    value: 'Indígena',
    label: 'Indígena',
  },
];
const ethnicity = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Mulatos',
    label: 'Mulatos',
  },
  {
    value: 'Caboclos',
    label: 'Caboclos',
  },
  {
    value: 'Cafuzo',
    label: 'Cafuzo',
  },
  {
    value: 'Indígenas',
    label: 'Indígenas',
  },
  {
    value: 'Brancos',
    label: 'Brancos',
  },
  {
    value: 'Negros',
    label: 'Negros',
  },
];
const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({ uf: ' ', city: ' ', street: ' ' });
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [find, setFind] = useState(false);

  function handleSubmit() {
    dispatch(
      signUpRequest(
        values.name,
        values.cpfcnpj,
        values.rg,
        values.cns,
        values.namefantasy,
        values.nmother,
        values.nresponsible,
        values.ceap,
        values.ddd,
        selectedDate,
        values.phoneone,
        values.phonetwo,
        values.male,
        values.breed,
        values.ethnicity,
        find,
        values.cep,
        values.street,
        values.number,
        values.city,
        values.neighborhood,
        values.uf,
        0,
        values.service
      )
    );
  }

  async function checkCep() {
    await ceps(values.cep)
      .then(async (res) => {
        setFind(true);
        await setValues({
          ...values,
          city: res.city,
          neighborhood: res.neighborhood,
          uf: res.state,
          street: res.street,
        });
      })
      .catch(() => {
        setFind(false);
        toast.error('Cep invalido!');
      });
  }

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  return (
    <Form autoComplete="off" noValidate>
      <Card style={{ backgroundColor: '#8CD4CC' }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Nome"
                name="name"
                style={{ backgroundColor: '#FFF', borderRadius: 5 }}
                value={values.name}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Nome da mãe"
                name="nmother"
                style={{ backgroundColor: '#FFF', borderRadius: 5 }}
                value={values.nmother}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Nome do responsável"
                name="nresponsible"
                style={{ backgroundColor: '#FFF', borderRadius: 5 }}
                value={values.nresponsible}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Cartão nacional de saúde (CNS)"
                name="cns"
                style={{ backgroundColor: '#FFF', borderRadius: 5 }}
                value={values.cns}
                onChange={handleChange}
                variant="outlined"
                type="number"
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <TextField
                fullWidth
                label="DDD"
                name="ddd"
                style={{ backgroundColor: '#FFF', borderRadius: 5 }}
                value={values.ddd}
                onChange={handleChange}
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Telefone fixo"
                name="phoneone"
                style={{ backgroundColor: '#FFF', borderRadius: 5 }}
                value={values.phoneone}
                onChange={handleChange}
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Telefone celular"
                name="phonetwo"
                style={{ backgroundColor: '#FFF', borderRadius: 5 }}
                value={values.phonetwo}
                onChange={handleChange}
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                <Datea
                  id="date-picker-dialog"
                  label="Nascimento"
                  format="dd/MM/yy"
                  inputVariant="outlined"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                label="Serviço"
                name="service"
                onChange={handleChange}
                select
                style={{ backgroundColor: '#FFF', borderRadius: 5 }}
                SelectProps={{ native: true }}
                value={values.service}
                variant="outlined"
              >
                {service.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                label="Sexo"
                name="male"
                onChange={handleChange}
                select
                style={{ backgroundColor: '#FFF', borderRadius: 5 }}
                SelectProps={{ native: true }}
                value={values.male}
                variant="outlined"
              >
                {sex.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                label="Raça/Cor"
                name="breed"
                onChange={handleChange}
                select
                style={{ backgroundColor: '#FFF', borderRadius: 5 }}
                SelectProps={{ native: true }}
                value={values.breed}
                variant="outlined"
              >
                {color.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                label="Etinia"
                name="ethnicity"
                onChange={handleChange}
                select
                style={{ backgroundColor: '#FFF', borderRadius: 5 }}
                SelectProps={{ native: true }}
                value={values.ethnicity}
                variant="outlined"
              >
                {ethnicity.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <TextFieldEdit
                fullWidth
                label="CEP"
                name="cep"
                style={{ backgroundColor: '#FFF', borderRadius: 5 }}
                value={values.cep}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <ButtonCPF color="#015D58" onClick={checkCep} disableRipple>
                Buscar cep
              </ButtonCPF>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextFieldEdit
                fullWidth
                label="Rua"
                name="street"
                style={{ backgroundColor: '#FFF', borderRadius: 5 }}
                value={values.street}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextFieldEdit
                fullWidth
                label="Numero"
                name="number"
                style={{ backgroundColor: '#FFF', borderRadius: 5 }}
                value={values.number}
                onChange={handleChange}
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item md={10} xs={12}>
              <TextFieldEdit
                fullWidth
                label="Cidade"
                name="city"
                style={{ backgroundColor: '#FFF', borderRadius: 5 }}
                value={values.city}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextFieldEdit
                fullWidth
                label="UF"
                name="uf"
                style={{ backgroundColor: '#FFF', borderRadius: 5 }}
                value={values.uf}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>

        <Box display="flex" justifyContent="flex-end" p={2}>
          <ButtonUpdate onClick={handleSubmit} color="#015D58">
            {loading ? 'Carregando...' : 'Enviar'}
          </ButtonUpdate>
        </Box>
      </Card>
    </Form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
