export function signInRequest(cpf, password, history) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { cpf, password, history },
  };
}

export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function signUpRequest(
  name,
  cpfcnpj,
  rg,
  cns,
  namefantasy,
  nmother,
  nresponsible,
  ceap,
  ddd,
  selectedDate,
  phoneone,
  phonetwo,
  male,
  breed,
  ethnicity,
  find,
  cep,
  street,
  number,
  city,
  neighborhood,
  uf,
  cibge,
  service_id
) {
  return {
    type: '@auth/SIGN_UP_REQUEST_LANDING',
    payload: {
      name,
      cpfcnpj,
      rg,
      cns,
      namefantasy,
      nmother,
      nresponsible,
      ceap,
      ddd,
      selectedDate,
      phoneone,
      phonetwo,
      male,
      breed,
      ethnicity,
      find,
      cep,
      street,
      number,
      city,
      neighborhood,
      uf,
      cibge,
      service_id,
    },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_IN_FAILURE',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}
