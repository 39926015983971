import styled, { keyframes } from 'styled-components';
import { darken } from 'polished';
import Input from 'react-input-mask';
import PerfectScrollbar from 'react-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

const fade = keyframes`{
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: ${fade} 1s;
  h1 {
    margin-top: 2%;
    font: 40px 'Bebas Neue', sans-serif;
    color: #0b7269;
  }
  h2 {
    font: 20px 'Bebas Neue', sans-serif;
    color: #0b7269;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 20px;
  }
  button {
    border: 0;
    background: none;
  }
  strong {
    color: #fff;
    font-size: 24px;
  }
  ul {
    overflow: auto;
    width: 750px;
    height: 400px;
    display: grid;
    grid-gap: 15px;
    margin-top: 30px;
  }
`;

export const ContainerCalendar = styled.div`
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-shadow: 5px 5px 5px rbga(0, 0, 0, 0.3);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  .highlight {
    color: #fff;
    background: #10bcb1;
  }
`;
export const ContainerScroll = styled(PerfectScrollbar)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
`;

export const Button = styled.button`
  height: 35px;
  width: 100%;
  background: #4285f4;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.03, '#4285F4')};
  }
`;
export const InputBig = styled(Input)`
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid #0b7269;
  border-radius: 4px;
  height: 28px;
  width: 70%;
  color: #0b7269;
  margin: 0 0 10px;
  padding: 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  &::placeholder {
    color: rgba(255, 255, 255, 1);
  }
`;

export const RegisterButton = styled.button`
  margin: 20px 0px;
  padding: 20px;
  width: 80%;
  height: 50;
  background: #0b7269;
  color: #fff;
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  transition: background 0.2s;
  align-self: flex-start;
  font: 15px 'Bebas Neue', sans-serif;
  &:hover {
    background: ${darken(0.1, '#0b7269')};
  }
`;
export const ConfirmationButton = styled.button`
  background: #4285f4;
  font-weight: bold;
  align-self: center;
  padding: 10;
  color: #fff;
  border: 0;
  height: 40;
  width: 150;
  border-radius: 4px;
  font-size: 14px;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.03, '#4285F4')};
  }
`;

export const Time = styled.li`
  padding: 20px;
  border-radius: 5px;
  margin-right: 20px;
  background: #fff;
  text-shadow: 5px 5px 5px rbga(0, 0, 0, 0.3);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  div {
    display: flex;
    flex-direction: row;
  }
  strong {
    display: block;
    color: ${(props) =>
      props.available === 'screening' ? '#4285F4' : '#0F7269'};
    font-size: 20px;
    font-weight: bold;
  }
  span {
    display: block;
    margin-top: 5px;
    font-size: 14px;
    color: ${(props) =>
      props.available === 'screening' ? '#4285F4' : '#0F7269'};
  }
`;
